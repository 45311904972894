import CountriesList from "../modules/chatBot/components/CountryCard/CountriesList";
import './ChatBotPage.scss'
const ChatBot = () => {

    return(
      <div className="chatbot-container">
        <CountriesList/>
      </div>
    )
}

export default ChatBot;