import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import './Cards.scss';

const Card = () => {
    const [voipLoaded, setVoipLoaded] = useState(false);
    const [voipButton, setVoipButton] = useState(false);
    const [appsButton, setAppsButton] = useState(false);
    const [cloudButton, setCloudButton] = useState(false);
    const [serviceButton, setServiceButton] = useState(false);
    const [appsLoaded, setAppsLoaded] = useState(false);
    const [cloudLoaded, setCloudLoaded] = useState(false);
    const [serviceLoaded, setServiceLoaded] = useState(false);  

    useEffect(() => {
        const voipTimer = setTimeout(() => {
            setVoipLoaded(true);
        }, 1000); // Появление первой карточки через 1 секунду

        const appsTimer = setTimeout(() => {
            setAppsLoaded(true);
        }, 3500); // Появление второй карточки через 2 секунды

        const cloudTimer = setTimeout(() => {
            setCloudLoaded(true);
        }, 5500); // Появление третьей карточки через 3 секунды

        const serviceTimer = setTimeout(() => {
            setServiceLoaded(true);
        }, 7500); // Появление четвертой карточки через 4 секунды

        return () => {
            clearTimeout(voipTimer);
            clearTimeout(appsTimer);
            clearTimeout(cloudTimer);
            clearTimeout(serviceTimer);
        };
    }, []);

    useEffect(() =>
    {
        const voipButtonTimer = setTimeout(() =>
        {
            setVoipButton(true)
        }, 2500)
        const appsButtonTimer = setTimeout(() =>
        {
            setAppsButton(true);
        }, 4500)
        const cloudButtonTimer = setTimeout(() =>
        {
            setCloudButton(true);
        }, 6500)
        const serviceButtonTimer = setTimeout(() =>
        {
            setServiceButton(true);
        }, 8500)
        return () => {
            clearTimeout(voipButtonTimer); 
            clearTimeout(appsButtonTimer); 
            clearTimeout(cloudButtonTimer);
            clearTimeout(serviceButtonTimer); 
        }
    },[])
    
    return (
        <div className='card'>
            {voipLoaded  ? <Link to='/voip'> <div className="card__voip">
                <div className="card__wrapper-svg">
                    <div className="card__wrapper-rotate">
                        <svg xmlns="http://www.w3.org/2000/svg" width="271" height="289" viewBox="0 0 271 289" fill="none">
                        <path d="M43.9547 15.1539L0.992149 145.507C0.466319 147.102 0.389663 148.771 0.746587 150.333C1.0587 151.918 1.79699 153.42 2.93096 154.665L92.1189 253.253C93.9063 255.237 95.91 257.018 98.0849 258.545C100.957 260.563 104.12 262.153 107.491 263.264L114.007 265.412L114.771 265.664L181.337 287.615C192.73 291.37 205.009 285.179 208.779 273.778L269.508 89.4811C273.263 78.0884 267.072 65.809 255.679 62.0542L71.3965 1.31704C59.9889 -2.4303 47.7096 3.76127 43.9547 15.1539Z" fill="url(#paint0_linear_1624_11505)"/>
                        <defs>
                            <linearGradient id="paint0_linear_1624_11505" x1="247.945" y1="40.4351" x2="50.6553" y2="209.257" gradientUnits="userSpaceOnUse">
                            <stop offset="0.18" stopColor="#D0C7F6"/>
                            <stop offset="0.435" stopColor="#DDD6F9"/>
                            <stop offset="0.52" stopColor="#DDD6F9"/>
                            <stop offset="1" stopColor="#B4A8F2"/>
                            </linearGradient>
                        </defs>
                        </svg>
                        <svg className='card__wrapped' xmlns="http://www.w3.org/2000/svg" width="114" height="127" viewBox="0 0 114 127" fill="none">
                        <path d="M2.01429 3.40545C-0.0964358 9.8096 4.28295 10.543 10.264 9.21347L49.4474 0.529394C61.1552 -2.07642 72.7547 5.32145 75.3456 17.0369L94.5293 103.5C94.5293 103.5 94.552 103.583 94.5596 103.635C98.2891 120.172 108.936 124.678 114.007 126.412L107.491 124.264C104.12 123.153 100.957 121.562 98.085 119.545C95.91 118.018 93.9064 116.237 92.119 114.253L2.93105 15.6652C1.79708 14.4195 1.05879 12.9182 0.746679 11.3331C0.389755 9.77055 0.466418 8.10181 0.992248 6.50639L2.01429 3.40545Z" fill="url(#paint0_linear_1624_11506)"/>
                        <defs>
                            <linearGradient id="paint0_linear_1624_11506" x1="91.4897" y1="34.2282" x2="57.8653" y2="65.0474" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#E4E1ED"/>
                            <stop offset="0.305" stopColor="#ECEBF4"/>
                            <stop offset="0.545" stopColor="#ECEBF4"/>
                            <stop offset="1" stopColor="#C8C0D9"/>
                            </linearGradient>
                        </defs>
                        </svg>
                        <span className="name-card">VoIP</span>
                    </div>
                    {voipButton  ? <svg className='button' xmlns="http://www.w3.org/2000/svg" width="48" height="49" viewBox="0 0 48 49" fill="none">
                        <g style={{mixBlendMode:'multiply'}} opacity="0">
                            <path opacity="0.06" d="M44.0918 35.3096C38.4126 46.5785 24.7289 51.1398 13.46 45.5053C2.23584 39.8709 -2.32538 26.1425 3.35379 14.8289C9.03296 3.55997 22.7166 -1.00126 33.9855 4.63319C45.2097 10.3124 49.7262 23.996 44.0918 35.3096Z" fill="#F6F6F6"/>
                            <path opacity="0.12" d="M43.4116 34.8076C37.8665 45.8082 24.4959 50.28 13.4953 44.735C2.49473 39.2347 -1.88763 25.8193 3.65739 14.774C9.2024 3.77343 22.573 -0.698354 33.5736 4.84666C44.5742 10.3917 48.9566 23.7623 43.4116 34.8076Z" fill="#EDEDED"/>
                            <path opacity="0.19" d="M42.747 34.2729C37.3361 45.0499 24.2785 49.3875 13.5462 43.9767C2.81391 38.5658 -1.479 25.5082 3.93186 14.7312C9.34272 3.99891 22.4003 -0.383438 33.1326 5.02742C43.8649 10.4383 48.1578 23.4959 42.747 34.2729Z" fill="#E3E4E4"/>
                            <path opacity="0.25" d="M42.0706 33.7853C36.7939 44.294 24.0493 48.4975 13.5854 43.2655C3.12138 37.9888 -1.08209 25.2442 4.19461 14.7355C9.47132 4.22681 22.2159 -0.0213895 32.6799 5.25532C43.1439 10.532 47.3474 23.2766 42.0706 33.7853Z" fill="#DADBDB"/>
                            <path opacity="0.31" d="M41.41 33.2405C36.2674 43.4809 23.8358 47.595 13.6402 42.4524C3.4445 37.3099 -0.669553 24.8783 4.473 14.6379C9.61555 4.39751 22.0471 0.283464 32.2428 5.3813C42.4385 10.5239 46.5525 22.9554 41.41 33.1958V33.2405Z" fill="#D1D1D2"/>
                            <path opacity="0.38" d="M40.7276 32.7055C35.7192 42.6776 23.6006 46.7022 13.6286 41.6938C3.7012 36.6854 -0.323405 24.5669 4.68499 14.5948C9.69339 4.6227 21.8119 0.598091 31.784 5.60649C41.7114 10.6149 45.736 22.7334 40.7276 32.7055Z" fill="#C8C8C9"/>
                            <path opacity="0.44" d="M40.0241 32.2138C35.1498 41.9175 23.3443 45.8527 13.6405 40.9785C3.93676 36.1042 0.0463078 24.2987 4.96527 14.5949C9.83952 4.89114 21.645 0.955971 31.3488 5.83022C41.0079 10.7045 44.8983 22.51 40.0241 32.2585V32.2138Z" fill="#BEBFC0"/>
                            <path opacity="0.5" d="M39.3476 31.6829C34.6075 41.1184 23.115 44.9641 13.6796 40.2241C4.2441 35.484 0.487804 23.9915 5.2279 14.556C9.96799 5.12054 21.4605 1.2748 30.896 6.01489C40.3314 10.755 44.0877 22.2475 39.3476 31.7277V31.6829Z" fill="#B5B6B8"/>
                            <path opacity="0.56" d="M38.6791 31.1463C34.0732 40.3135 22.8937 44.0251 13.7266 39.4191C4.55939 34.8132 0.892528 23.6337 5.49847 14.4666C10.1044 5.25468 21.2839 1.54309 30.451 6.14903C39.6182 10.755 43.285 21.9344 38.6791 31.1463Z" fill="#ACADAF"/>
                            <path opacity="0.62" d="M38.0106 30.6559C33.5388 39.5995 22.6724 43.1769 13.7735 38.7051C4.87469 34.2334 1.29726 23.3669 5.76905 14.4233C10.2408 5.47978 21.1073 1.90235 30.0061 6.37414C38.905 10.8459 42.4824 21.7124 38.0106 30.6559Z" fill="#A3A4A6"/>
                            <path opacity="0.69" d="M37.3421 30.1095C33.0045 38.7847 22.4511 42.2727 13.8206 37.9351C5.19001 33.5975 1.70201 23.0441 6.03964 14.3688C10.3773 5.69353 20.9307 2.20554 29.5612 6.54317C38.1918 10.8808 41.6798 21.4342 37.2974 30.1095H37.3421Z" fill="#999B9D"/>
                            <path opacity="0.75" d="M36.666 29.5787C32.4625 37.9856 22.2221 41.3842 13.8599 37.1807C5.45289 32.9772 2.09905 22.7368 6.34725 14.3299C10.5954 5.92292 20.7911 2.52436 29.1981 6.72784C37.5603 10.9313 40.9589 21.1717 36.7107 29.5787H36.666Z" fill="#909294"/>
                            <path opacity="0.81" d="M35.9914 29.0887C31.8773 37.2273 21.9947 40.5364 13.856 36.4224C5.71738 32.3531 2.45298 22.4257 6.56703 14.2871C10.6811 6.14842 20.5637 2.8393 28.7024 6.90862C36.7963 10.9779 40.0607 20.9053 35.9914 29.044V29.0887Z" fill="#87888B"/>
                            <path opacity="0.88" d="M35.3188 28.5579C31.339 36.4282 21.7693 39.6479 13.899 35.668C6.02866 31.7329 2.8984 22.1185 6.83357 14.2482C10.8135 6.37784 20.3831 3.15815 28.2534 7.09332C36.1238 11.0285 39.254 20.6428 35.3188 28.5579Z" fill="#7E7F82"/>
                            <path opacity="0.94" d="M34.6504 28.0152C30.8046 35.6172 21.548 38.7027 13.946 34.9017C6.34396 31.1007 3.30314 21.7994 7.10416 14.1974C10.9499 6.55061 20.2065 3.50979 27.8085 7.31081C35.4106 11.1118 38.4514 20.4131 34.6504 28.0599V28.0152Z" fill="#747679"/>
                            <path d="M33.9856 27.5291C30.274 34.9075 21.3304 37.8589 13.9967 34.1473C6.66295 30.4805 3.71158 21.4922 7.42316 14.1584C11.1347 6.77999 20.0783 3.82862 27.412 7.49548C34.7458 11.2071 37.6971 20.1506 33.9856 27.5291Z" fill="#6B6D70"/>
                        </g>
                        <path d="M36.6353 11.0018C41.9121 20.7056 38.6477 32.3322 29.3016 36.9829C20.0003 41.6336 8.15007 37.5642 2.87336 27.9052C-2.40335 18.2014 0.861064 6.57476 10.2071 1.9241C19.5531 -2.72656 31.3586 1.34277 36.6801 11.0018H36.6353Z" fill="url(#paint0_radial_1503_11758)"/>
                        <g style={{mixBlendMode:'screen'}} opacity="0.54">
                            <path style={{mixBlendMode:'screen'}} d="M11.6824 2.10234C20 -0.938472 30.1062 1.61045 36.1878 11.4484C38.066 14.4445 38.5579 14.8916 36.5009 11.1353C31.2241 1.43157 19.5081 -2.68247 10.2068 2.01291C7.43425 3.39916 9.44655 2.90726 11.6824 2.10234Z" fill="url(#paint1_radial_1503_11758)"/>
                        </g>
                        <g style={{mixBlendMode:'soft-light'}} opacity="0.61">
                            <path d="M38.1555 21.1055C37.2612 26.2927 34.0862 30.8987 29.1225 33.3582C20.3131 37.7405 9.13363 33.9395 4.16994 24.7723C1.21857 19.3615 1.08441 13.2798 3.3203 8.31616C-0.033537 13.6823 -0.391277 20.9266 3.052 27.2318C8.19455 36.5779 19.6423 40.513 28.6306 35.9965C34.4886 33.0899 37.8872 27.3213 38.1108 21.0608L38.1555 21.1055Z" fill="url(#paint2_radial_1503_11758)"/>
                        </g>
                        <g style={{mixBlendMode:'overlay'}}>
                            <path opacity="0.11" d="M33.1146 6.80534C40.0459 14.4968 39.7329 25.721 32.4439 31.8473C25.1101 38.0184 13.5282 36.7216 6.59693 29.0301C-0.334338 21.3386 -0.0660243 10.1145 7.2677 3.9434C14.6014 -2.18295 26.1834 -0.930848 33.1146 6.76062V6.80534Z" fill="url(#paint3_linear_1503_11758)"/>
                            <path opacity="0.22" d="M32.9736 6.76485C39.8155 14.3222 39.5024 25.4122 32.2134 31.4938C24.9691 37.5755 13.5214 36.3681 6.67953 28.766C-0.162298 21.164 0.150727 10.1187 7.43974 4.03706C14.684 -2.04457 26.1318 -0.837183 32.9736 6.76485Z" fill="url(#paint4_linear_1503_11758)"/>
                            <path opacity="0.33" d="M32.8851 6.67207C39.6375 14.14 39.2798 25.0511 32.0802 31.088C24.9253 37.1249 13.6117 35.9176 6.90404 28.4944C0.151646 21.0265 0.509382 10.1154 7.70896 4.07844C14.8638 -1.91375 26.1774 -0.751089 32.8851 6.71679V6.67207Z" fill="url(#paint5_linear_1503_11758)"/>
                            <path opacity="0.44" d="M32.7576 6.6353C39.3758 13.969 39.0181 24.746 31.9079 30.6935C24.7978 36.641 13.663 35.523 7.0448 28.1893C0.426556 20.8556 0.784301 10.0786 7.89444 4.08638C15.0046 -1.86109 26.1393 -0.743146 32.7576 6.6353Z" fill="url(#paint6_linear_1503_11758)"/>
                            <path opacity="0.56" d="M32.6085 6.52984C39.1373 13.7741 38.7349 24.417 31.7142 30.275C24.6935 36.1778 13.6929 35.0598 7.16408 27.8603C0.63527 20.616 1.03773 9.97311 8.05843 4.11507C15.0791 -1.78768 26.0797 -0.669738 32.6085 6.52984Z" fill="url(#paint7_linear_1503_11758)"/>
                            <path opacity="0.67" d="M32.5243 6.50026C38.9637 13.6104 38.5165 24.1191 31.5852 29.9324C24.654 35.7457 13.7875 34.7172 7.39288 27.6071C0.953507 20.497 1.40069 9.98826 8.33196 4.17494C15.2632 -1.63838 26.0849 -0.609875 32.5243 6.50026Z" fill="url(#paint8_linear_1503_11758)"/>
                            <path opacity="0.78" d="M32.3933 6.44559C38.6985 13.4663 38.2514 23.7961 31.4095 29.52C24.523 35.2886 13.8354 34.3048 7.53019 27.2841C1.22498 20.2634 1.62743 9.93358 8.51398 4.16498C15.3558 -1.60362 26.0434 -0.575115 32.3933 6.40087V6.44559Z" fill="url(#paint9_linear_1503_11758)"/>
                            <path opacity="0.89" d="M32.2556 6.3521C38.4714 13.2386 37.9795 23.4343 31.2271 29.1135C24.43 34.8374 13.8765 33.8536 7.66076 26.967C1.44498 20.0805 1.89216 9.88481 8.73399 4.20564C15.5311 -1.47353 26.0398 -0.534451 32.3003 6.3521H32.2556Z" fill="url(#paint10_linear_1503_11758)"/>
                            <path d="M32.1696 6.30751C38.296 13.0599 37.8041 23.1214 31.0517 28.7559C24.344 34.3903 13.9247 33.496 7.84312 26.6989C1.71677 19.9465 2.20867 9.88494 8.96107 4.25049C15.6687 -1.38396 26.0433 -0.444884 32.1696 6.30751Z" fill="url(#paint11_linear_1503_11758)"/>
                        </g>
                        <path opacity="0.05" d="M4.99816 12.7508C3.92493 18.3406 5.31118 21.4261 6.1161 23.3043C6.83159 25.0483 4.19323 23.349 4.14852 23.3043C0.884112 18.1617 3.12001 12.1248 3.88021 11.6329C4.64041 11.141 5.13231 12.1248 4.99816 12.7508Z" fill="white"/>
                        <path opacity="0.1" d="M4.92119 12.9345C3.93739 18.2112 5.18949 21.2073 5.99441 23.0854C6.7099 24.7847 4.2057 23.2196 4.16098 23.0854C1.07545 18.1665 3.13247 12.3979 3.89268 11.8612C4.65288 11.3246 5.05534 12.3531 4.9659 12.9345H4.92119Z" fill="white"/>
                        <path opacity="0.15" d="M4.78634 13.1045C3.84726 18.1129 5.05464 21.0195 5.81485 22.853C6.48562 24.5523 4.16029 23.0318 4.07085 22.853C1.16419 18.1129 3.08706 12.702 3.80255 12.1207C4.47332 11.5394 4.87577 12.6126 4.78634 13.1492V13.1045Z" fill="white"/>
                        <path opacity="0.2" d="M4.69571 13.2852C3.84607 17.9806 4.9193 20.7978 5.6795 22.6312C6.35027 24.2858 4.15909 22.8548 4.06966 22.5865C1.34187 18.0253 3.13059 12.9274 3.75664 12.3014C4.38269 11.7201 4.78514 12.7486 4.69571 13.2852Z" fill="white"/>
                        <path opacity="0.25" d="M4.60612 13.4604C3.8012 17.8875 4.82971 20.6152 5.54519 22.404C6.21596 24.0585 4.15894 22.6723 4.0695 22.3592C1.52059 17.9769 3.17515 13.1921 3.75648 12.566C4.33781 11.94 4.69556 13.0132 4.60612 13.4604Z" fill="white"/>
                        <path opacity="0.3" d="M4.47147 13.6411C3.71126 17.7551 4.65034 20.3935 5.36583 22.1822C5.99188 23.792 4.11372 22.4952 3.97957 22.0928C1.60952 17.934 3.12993 13.4622 3.66654 12.7915C4.20316 12.1207 4.5609 13.2386 4.47147 13.6411Z" fill="white"/>
                        <path opacity="0.35" d="M4.3816 13.8259C3.71083 17.6717 4.51576 20.2206 5.23124 21.9646C5.85729 23.5744 4.11329 22.367 3.97914 21.8752C1.74325 17.8953 3.17422 13.7365 3.66612 13.021C4.15801 12.3055 4.47104 13.4682 4.42632 13.8259H4.3816Z" fill="white"/>
                        <path opacity="0.4" d="M4.29786 14.0043C3.67181 17.5817 4.43201 19.9965 5.10278 21.7404C5.72883 23.3056 4.11898 22.1876 3.94011 21.651C1.88309 17.85 3.17991 14.0043 3.62709 13.2888C4.07426 12.5733 4.34258 13.6912 4.29786 14.049V14.0043Z" fill="white"/>
                        <path opacity="0.44" d="M4.15388 14.1757C3.61727 17.4401 4.24332 19.8101 4.91409 21.5094C5.49542 23.0745 4.06445 22.0013 3.88558 21.3753C2.00743 17.7531 3.17009 14.2651 3.57255 13.4602C3.97501 12.7 4.24332 13.8627 4.1986 14.1757H4.15388Z" fill="white"/>
                        <path opacity="0.49" d="M4.07042 14.3516C3.57852 17.3477 4.15985 19.5836 4.7859 21.2829C5.36723 22.8033 4.07042 21.8195 3.84683 21.1487C2.14755 17.7502 3.17606 14.5305 3.53381 13.7255C3.89155 12.9206 4.11513 14.128 4.07042 14.3963V14.3516Z" fill="white"/>
                        <path opacity="0.54" d="M3.93041 14.5379C3.48323 17.221 3.93042 19.4122 4.60119 21.0667C5.18252 22.5871 4.01985 21.6481 3.79626 20.9326C2.27586 17.7129 3.17021 14.8062 3.43852 13.9566C3.75155 13.1069 3.97513 14.359 3.93041 14.5379Z" fill="white"/>
                        <path opacity="0.59" d="M3.83728 14.7073C3.47954 17.1221 3.83728 19.1791 4.46333 20.8337C4.99994 22.3094 4.01615 21.5044 3.79256 20.6548C2.45103 17.614 3.16651 15.0651 3.43482 14.1707C3.70313 13.3211 3.882 14.5285 3.83728 14.7073Z" fill="white"/>
                        <path opacity="0.64" d="M3.75808 14.8873C3.44505 17.0337 3.75808 19.0013 4.33941 20.5665C4.87602 22.0422 4.02639 21.2819 3.75808 20.3876C2.5507 17.5256 3.17675 15.2898 3.40034 14.3507C3.62393 13.4563 3.75808 14.7084 3.75808 14.8426V14.8873Z" fill="white"/>
                        <path opacity="0.69" d="M3.6168 15.0795C3.39322 16.9129 3.61681 18.7911 4.15342 20.3562C4.69004 21.7872 3.97455 21.1164 3.70624 20.1773C2.67773 17.4943 3.16963 15.5714 3.3485 14.6323C3.52737 13.6933 3.66152 14.9901 3.66152 15.0795H3.6168Z" fill="white"/>
                        <path opacity="0.74" d="M3.53767 15.2462C3.3588 16.8114 3.44823 18.6001 4.02956 20.1205C4.52146 21.5515 3.98484 20.9254 3.67182 19.8969C2.82218 17.4374 3.17992 15.8276 3.31407 14.8438C3.44823 13.86 3.53767 15.2015 3.53767 15.2462Z" fill="white"/>
                        <path opacity="0.79" d="M3.43701 15.4329C3.43701 16.7297 3.34758 18.3842 3.88419 19.9046C4.37609 21.2909 3.97363 20.7543 3.6606 19.681C2.98983 17.4004 3.21342 16.1036 3.30286 15.0751C3.39229 14.0913 3.48173 15.4329 3.48173 15.4329H3.43701Z" fill="white"/>
                        <path style={{mixBlendMode:'screen'}} d="M32.0212 7.33541C37.8793 14.6244 37.0743 24.9542 30.2772 30.4098C23.4801 35.8654 13.195 34.3897 7.38169 27.1007C1.52365 19.8117 2.32858 9.48187 9.12569 4.02629C15.9228 -1.42929 26.1632 0.046401 32.0212 7.33541Z" fill="url(#paint12_linear_1503_11758)"/>
                        <g style={{mixBlendMode:'overlay'}} opacity="0.56">
                            <path style={{mixBlendMode:'screen'}} d="M29.353 29.6408C33.2435 26.7789 35.6136 22.4412 36.4185 17.7458C34.585 17.9247 32.7069 18.2825 30.8287 18.7296C22.6454 20.8314 15.893 25.3926 11.9131 30.8035C17.3687 33.7548 24.0316 33.576 29.353 29.6408Z" fill="url(#paint13_linear_1503_11758)"/>
                        </g>
                        <defs>
                        <radialGradient id="paint0_radial_1503_11758" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(38.1413 32.304) rotate(-26.59) scale(49.9033 52.6155)">
                        <stop offset="0.32" stopColor="#001C80"/>
                        <stop offset="0.33" stopColor="#001480"/>
                        <stop offset="0.75" stopColor="#002480"/>
                        <stop offset="1" stopColor="#002C80"/>
                        </radialGradient>
                        <radialGradient id="paint1_radial_1503_11758" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(23.2016 -1.08583) rotate(9.69) scale(13.7284 13.7284)">
                        <stop offset="0.1" stopColor="#A9A9A9"/>
                        <stop offset="0.48" stopColor="#A9A9A9"/>
                        <stop offset="0.52" stopColor="#99A599"/>
                        <stop offset="0.69" stopColor="#589558"/>
                        <stop offset="0.83" stopColor="#288928"/>
                        <stop offset="0.94" stopColor="#0A820A"/>
                        <stop offset="1" stopColor="#008000"/>
                        </radialGradient>
                        <radialGradient id="paint2_radial_1503_11758" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(34.8639 35.2903) rotate(9.69) scale(34.1197 34.1197)">
                        <stop offset="0.08" stopColor="#2C2C2C"/>
                        <stop offset="0.12" stopColor="#434343"/>
                        <stop offset="0.23" stopColor="#787878"/>
                        <stop offset="0.34" stopColor="#A2A2A2"/>
                        <stop offset="0.43" stopColor="#C0C0C0"/>
                        <stop offset="0.51" stopColor="#D2D2D2"/>
                        <stop offset="0.57" stopColor="#D9D9D9"/>
                        <stop offset="0.66" stopColor="#D6D6D6"/>
                        <stop offset="0.73" stopColor="#CDCDCD"/>
                        <stop offset="0.79" stopColor="#BDBDBD"/>
                        <stop offset="0.84" stopColor="#A8A8A8"/>
                        <stop offset="0.89" stopColor="#8C8C8C"/>
                        <stop offset="0.93" stopColor="#696969"/>
                        <stop offset="0.97" stopColor="#414141"/>
                        <stop offset="0.99" stopColor="#2C2C2C"/>
                        </radialGradient>
                        <linearGradient id="paint3_linear_1503_11758" x1="28.2915" y1="38.2522" x2="13.0608" y2="-1.25029" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#003380"/>
                        <stop offset="0.44" stopColor="#001C80"/>
                        <stop offset="0.58" stopColor="#024481"/>
                        <stop offset="0.73" stopColor="#034989"/>
                        <stop offset="1" stopColor="#053694"/>
                        </linearGradient>
                        <linearGradient id="paint4_linear_1503_11758" x1="28.9489" y1="39.8505" x2="13.7791" y2="0.544136" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#002C80"/>
                        <stop offset="0.44" stopColor="#002480"/>
                        <stop offset="0.58" stopColor="#033682"/>
                        <stop offset="0.73" stopColor="#062B8A"/>
                        <stop offset="1" stopColor="#0B4295"/>
                        </linearGradient>
                        <linearGradient id="paint5_linear_1503_11758" x1="29.9468" y1="41.3991" x2="14.9703" y2="2.76976" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#002480"/>
                        <stop offset="0.44" stopColor="#003380"/>
                        <stop offset="0.58" stopColor="#052883"/>
                        <stop offset="0.73" stopColor="#09358B"/>
                        <stop offset="1" stopColor="#103696"/>
                        </linearGradient>
                        <linearGradient id="paint6_linear_1503_11758" x1="30.5472" y1="42.8377" x2="15.6204" y2="4.48917" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#002C80"/>
                        <stop offset="0.44" stopColor="#001C80"/>
                        <stop offset="0.58" stopColor="#072A84"/>
                        <stop offset="0.74" stopColor="#0D298C"/>
                        <stop offset="1" stopColor="#152A97"/>
                        </linearGradient>
                        <linearGradient id="paint7_linear_1503_11758" x1="30.9877" y1="44.2774" x2="16.1554" y2="6.17409" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#003380"/>
                        <stop offset="0.44" stopColor="#004380"/>
                        <stop offset="0.58" stopColor="#083A85"/>
                        <stop offset="0.78" stopColor="#12358F"/>
                        <stop offset="1" stopColor="#1B3E98"/>
                        </linearGradient>
                        <linearGradient id="paint8_linear_1503_11758" x1="31.744" y1="45.7189" x2="17.1066" y2="8.27991" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#004380"/>
                        <stop offset="0.44" stopColor="#002480"/>
                        <stop offset="0.45" stopColor="#002C80"/>
                        <stop offset="0.58" stopColor="#0A2D86"/>
                        <stop offset="0.81" stopColor="#174892"/>
                        <stop offset="1" stopColor="#205F99"/>
                        </linearGradient>
                        <linearGradient id="paint9_linear_1503_11758" x1="32.1881" y1="46.9529" x2="17.6037" y2="9.79036" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#002480"/>
                        <stop offset="0.44" stopColor="#002C80"/>
                        <stop offset="0.47" stopColor="#032681"/>
                        <stop offset="0.515625" stopColor="#072A83"/>
                        <stop offset="0.58" stopColor="#0C3D87"/>
                        <stop offset="0.83" stopColor="#1D3E94"/>
                        <stop offset="1" stopColor="#25549A"/>
                        </linearGradient>
                        <linearGradient id="paint10_linear_1503_11758" x1="32.5222" y1="48.1955" x2="17.9915" y2="11.268" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#002480"/>
                        <stop offset="0.44" stopColor="#003380"/>
                        <stop offset="0.49" stopColor="#053F83"/>
                        <stop offset="0.58" stopColor="#0D2F88"/>
                        <stop offset="0.85" stopColor="#224295"/>
                        <stop offset="1" stopColor="#2B4A9B"/>
                        </linearGradient>
                        <linearGradient id="paint11_linear_1503_11758" x1="32.7304" y1="49.321" x2="18.3357" y2="12.6827" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#002480"/>
                        <stop offset="0.44" stopColor="#001C80"/>
                        <stop offset="0.62" stopColor="#12348A"/>
                        <stop offset="0.86" stopColor="#274697"/>
                        <stop offset="1" stopColor="#305B9C"/>
                        </linearGradient>
                        <linearGradient id="paint12_linear_1503_11758" x1="14.4412" y1="-3.61095" x2="29.9321" y2="38.0819" gradientUnits="userSpaceOnUse">
                        <stop offset="0.1" stopColor="white"/>
                        <stop offset="0.15" stopColor="#BCBCBC"/>
                        <stop offset="0.2" stopColor="#7A7A7A"/>
                        <stop offset="0.24" stopColor="#454545"/>
                        <stop offset="0.28" stopColor="#1F1F1F"/>
                        <stop offset="0.31" stopColor="#080808"/>
                        <stop offset="0.33"/>
                        <stop offset="0.48" stopColor="#020202"/>
                        <stop offset="0.57" stopColor="#0A0A0A"/>
                        <stop offset="0.64" stopColor="#181818"/>
                        <stop offset="0.71" stopColor="#2D2D2D"/>
                        <stop offset="0.76" stopColor="#474747"/>
                        <stop offset="0.82" stopColor="#676767"/>
                        <stop offset="0.87" stopColor="#8D8D8D"/>
                        <stop offset="0.92" stopColor="#B9B9B9"/>
                        <stop offset="0.96" stopColor="#EAEAEA"/>
                        <stop offset="0.98" stopColor="white"/>
                        </linearGradient>
                        <linearGradient id="paint13_linear_1503_11758" x1="37.4677" y1="43.8801" x2="23.9316" y2="20.2802" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#A9A9A9"/>
                        <stop offset="0.06" stopColor="#8E8E8E"/>
                        <stop offset="0.19" stopColor="#5C5C5C"/>
                        <stop offset="0.31" stopColor="#343434"/>
                        <stop offset="0.42" stopColor="#171717"/>
                        <stop offset="0.51" stopColor="#060606"/>
                        <stop offset="0.58"/>
                        <stop offset="0.69" stopColor="#020202"/>
                        <stop offset="0.76" stopColor="#0A0A0A"/>
                        <stop offset="0.81" stopColor="#181818"/>
                        <stop offset="0.86" stopColor="#2D2D2D"/>
                        <stop offset="0.9" stopColor="#474747"/>
                        <stop offset="0.94" stopColor="#676767"/>
                        <stop offset="0.98" stopColor="#8C8C8C"/>
                        <stop offset="1" stopColor="#A9A9A9"/>
                        </linearGradient>
                        </defs>
                    </svg>: false}
                </div>
             </div>   </Link> : false}
            {appsLoaded  ?  <div className="card__apps">
                <div className="card__wrapper-svg">
                    <div className="card__wrapper-rotate">
                        <svg xmlns="http://www.w3.org/2000/svg" width="284" height="253" viewBox="0 0 284 253" fill="none">
                    <path d="M1.49002 81.0167L38.9759 213.049C39.4347 214.665 40.3065 216.089 41.4779 217.183C42.6249 218.321 44.0779 219.151 45.7152 219.547L174.835 251.2C177.428 251.841 180.086 252.193 182.742 252.238C186.252 252.299 189.763 251.844 193.177 250.874L199.777 249L200.551 248.781L267.981 229.648C279.521 226.372 286.219 214.362 282.951 202.808L229.942 16.1433C226.666 4.60394 214.656 -2.09464 203.116 1.18157L16.4599 54.1761C4.91237 57.467 -1.78619 69.4773 1.49002 81.0167Z" fill="url(#paint0_linear_1503_12143)"/>
                    <defs>
                        <linearGradient id="paint0_linear_1503_12143" x1="184.593" y1="-12.3868" x2="115.837" y2="238.006" gradientUnits="userSpaceOnUse">
                        <stop offset="0.105" stopColor="#F9D6F6"/>
                        <stop offset="0.175" stopColor="#F9D6F6"/>
                        <stop offset="0.47" stopColor="#F6C7F2"/>
                        <stop offset="1" stopColor="#F2A8ED"/>
                        </linearGradient>
                    </defs>
                        </svg>
                        <svg className='card__wrapped-apps' xmlns="http://www.w3.org/2000/svg" width="162" height="79" viewBox="0 0 162 79" fill="none">
                        <path d="M0.0841874 35.9078C1.92584 42.3944 5.96368 40.5471 10.1716 36.0935L37.7535 6.93892C45.9886 -1.78153 59.7413 -2.15676 68.4536 6.09304L132.805 66.9444C132.805 66.9444 132.87 66.9997 132.905 67.0388C145.263 78.6437 156.606 76.4071 161.777 75.0004L155.177 76.8743C151.763 77.8436 148.252 78.2993 144.742 78.2382C142.086 78.1932 139.428 77.841 136.835 77.1997L7.7152 45.5467C6.0779 45.1508 4.62495 44.3213 3.47799 43.1835C2.30658 42.0896 1.43472 40.6646 0.975922 39.0487L0.0841874 35.9078Z" fill="url(#paint0_linear_1503_12144)"/>
                        <defs>
                            <linearGradient id="paint0_linear_1503_12144" x1="91.4593" y1="11.2808" x2="80.8882" y2="55.6506" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#E4E1ED"/>
                            <stop offset="0.305" stopColor="#ECEBF4"/>
                            <stop offset="0.545" stopColor="#ECEBF4"/>
                            <stop offset="1" stopColor="#C8C0D9"/>
                            </linearGradient>
                        </defs>
                        </svg>
                        <span className="name-card">Apps</span>
                    </div>
                   {appsButton ? <svg className='button' xmlns="http://www.w3.org/2000/svg" width="51" height="50" viewBox="0 0 51 50" fill="none">
                    <g style={{mixBlendMode:'multiply'}} opacity="0">
                        <path opacity="0.06" d="M47.3515 36.3121C41.6724 47.581 27.9887 52.1422 16.7198 46.5078C5.49561 40.8733 0.934387 27.1449 6.61355 15.8313C12.2927 4.56241 25.9764 0.00118613 37.2453 5.63564C48.4695 11.3148 52.986 24.9985 47.3515 36.3121Z" fill="#F6F6F6"/>
                        <path opacity="0.12" d="M46.6713 35.8101C41.1263 46.8107 27.7557 51.2824 16.7551 45.7374C5.75449 40.2371 1.37214 26.8218 6.91715 15.7765C12.4622 4.77587 25.8328 0.304088 36.8334 5.8491C47.834 11.3941 52.2163 24.7648 46.6713 35.8101Z" fill="#EDEDED"/>
                        <path opacity="0.19" d="M46.0067 35.2753C40.5959 46.0523 27.5382 50.39 16.806 44.9791C6.07367 39.5683 1.78076 26.5106 7.19162 15.7336C12.6025 5.00135 25.6601 0.619003 36.3924 6.02986C47.1247 11.4407 51.4176 24.4983 46.0067 35.2753Z" fill="#E3E4E4"/>
                        <path opacity="0.25" d="M45.3304 34.7877C40.0537 45.2964 27.3091 49.4999 16.8451 44.2679C6.38114 38.9912 2.17767 26.2466 7.45438 15.7379C12.7311 5.22925 25.4757 0.981052 35.9396 6.25776C46.4036 11.5345 50.6071 24.2791 45.3304 34.7877Z" fill="#DADBDB"/>
                        <path opacity="0.31" d="M44.6697 34.243C39.5272 44.4834 27.0956 48.5974 16.8999 43.4548C6.70426 38.3123 2.59021 25.8807 7.73277 15.6403C12.8753 5.39995 25.3069 1.28591 35.5026 6.38374C45.6982 11.5263 49.8123 23.9579 44.6697 34.1982V34.243Z" fill="#D1D1D2"/>
                        <path opacity="0.38" d="M43.9874 33.708C38.979 43.68 26.8604 47.7046 16.8883 42.6962C6.96097 37.6878 2.93636 25.5693 7.94476 15.5972C12.9532 5.62514 25.0717 1.60053 35.0438 6.60893C44.9712 11.6173 48.9958 23.7359 43.9874 33.708Z" fill="#C8C8C9"/>
                        <path opacity="0.44" d="M43.2838 33.2162C38.4096 42.92 26.6041 46.8551 16.9003 41.9809C7.19653 37.1067 3.30607 25.3011 8.22504 15.5974C13.0993 5.89358 24.9048 1.95841 34.6086 6.83266C44.2676 11.7069 48.1581 23.5124 43.2838 33.2609V33.2162Z" fill="#BEBFC0"/>
                        <path opacity="0.5" d="M42.6074 32.6854C37.8673 42.1208 26.3748 45.9666 16.9393 41.2265C7.50387 36.4864 3.74757 24.9939 8.48766 15.5584C13.2278 6.12298 24.7203 2.27724 34.1557 7.01733C43.5912 11.7574 47.3475 23.2499 42.6074 32.7301V32.6854Z" fill="#B5B6B8"/>
                        <path opacity="0.56" d="M41.9389 32.1488C37.3329 41.3159 26.1535 45.0275 16.9863 40.4216C7.81916 35.8156 4.15229 24.6362 8.75823 15.469C13.3642 6.25712 24.5436 2.54553 33.7108 7.15147C42.8779 11.7574 46.5448 22.9369 41.9389 32.1488Z" fill="#ACADAF"/>
                        <path opacity="0.62" d="M41.2704 31.6584C36.7986 40.6019 25.9322 44.1794 17.0333 39.7076C8.13445 35.2358 4.55703 24.3694 9.02882 15.4258C13.5006 6.48222 24.367 2.90479 33.2659 7.37658C42.1647 11.8484 45.7422 22.7148 41.2704 31.6584Z" fill="#A3A4A6"/>
                        <path opacity="0.69" d="M40.6019 31.1119C36.2643 39.7872 25.7109 43.2752 17.0803 38.9375C8.44977 34.5999 4.96177 24.0465 9.2994 15.3712C13.637 6.69597 24.1905 3.20798 32.821 7.54561C41.4516 11.8832 44.9395 22.4367 40.5572 31.1119H40.6019Z" fill="#999B9D"/>
                        <path opacity="0.75" d="M39.9257 30.5811C35.7222 38.9881 25.4819 42.3866 17.1196 38.1831C8.71266 33.9797 5.35882 23.7393 9.60702 15.3323C13.8552 6.92536 24.0509 3.52681 32.4578 7.73028C40.8201 11.9338 44.2186 22.1742 39.9704 30.5811H39.9257Z" fill="#909294"/>
                        <path opacity="0.81" d="M39.2511 30.0911C35.1371 38.2298 25.2544 41.5389 17.1158 37.4248C8.97715 33.3555 5.71275 23.4282 9.82679 15.2895C13.9408 7.15086 23.8235 3.84174 31.9621 7.91106C40.0561 11.9804 43.3205 21.9078 39.2511 30.0464V30.0911Z" fill="#87888B"/>
                        <path opacity="0.88" d="M38.5786 29.5603C34.5987 37.4307 25.0291 40.6504 17.1588 36.6705C9.28842 32.7353 6.15817 23.121 10.0933 15.2506C14.0732 7.38028 23.6429 4.16059 31.5132 8.09577C39.3835 12.0309 42.5138 21.6453 38.5786 29.5603Z" fill="#7E7F82"/>
                        <path opacity="0.94" d="M37.9101 29.0176C34.0644 36.6197 24.8078 39.7052 17.2058 35.9042C9.60372 32.1032 6.56291 22.8018 10.3639 15.1998C14.2097 7.55305 23.4663 4.51224 31.0683 8.31325C38.6703 12.1143 41.7112 21.4156 37.9101 29.0623V29.0176Z" fill="#747679"/>
                        <path d="M37.2453 28.5315C33.5338 35.91 24.5902 38.8613 17.2564 35.1498C9.92272 31.4829 6.97134 22.4946 10.6829 15.1609C14.3945 7.78244 23.3381 4.83106 30.6718 8.49792C38.0055 12.2095 40.9569 21.1531 37.2453 28.5315Z" fill="#6B6D70"/>
                    </g>
                    <path d="M36.8951 11.0043C42.1718 20.708 38.9074 32.3347 29.5614 36.9853C20.2601 41.636 8.40983 37.5667 3.13313 27.9076C-2.14358 18.2038 1.12083 6.5772 10.4669 1.92654C19.8129 -2.72412 31.6184 1.34521 36.9398 11.0043H36.8951Z" fill="url(#paint0_radial_1503_11821)"/>
                    <g style={{mixBlendMode: 'screen'}} opacity="0.54">
                        <path style={{mixBlendMode: 'screen'}} d="M11.9422 2.10478C20.2597 -0.93603 30.366 1.61289 36.4476 11.4508C38.3257 14.4469 38.8176 14.8941 36.7606 11.1378C31.4839 1.43401 19.7678 -2.68003 10.4665 2.01535C7.69401 3.4016 9.70631 2.90971 11.9422 2.10478Z" fill="url(#paint1_radial_1503_11821)"/>
                    </g>
                    <g style={{mixBlendMode: 'soft-light'}} opacity="0.61">
                        <path d="M38.4153 21.1079C37.5209 26.2952 34.346 30.9011 29.3823 33.3606C20.5729 37.743 9.39339 33.9419 4.42971 24.7748C1.47833 19.3639 1.34417 13.2823 3.58007 8.3186C0.226229 13.6847 -0.131512 20.929 3.31176 27.2343C8.45432 36.5803 19.9021 40.5155 28.8904 35.999C34.7484 33.0923 38.147 27.3237 38.3706 21.0632L38.4153 21.1079Z" fill="url(#paint2_radial_1503_11821)"/>
                    </g>
                    <g style={{mixBlendMode: 'overlay'}}> 
                        <path opacity="0.11" d="M33.3744 6.80778C40.3057 14.4993 39.9926 25.7234 32.7036 31.8498C25.3699 38.0208 13.788 36.724 6.8567 29.0326C-0.0745724 21.3411 0.193741 10.1169 7.52747 3.94584C14.8612 -2.18051 26.4431 -0.928407 33.3744 6.76306V6.80778Z" fill="url(#paint3_linear_1503_11821)"/>
                        <path opacity="0.22" d="M33.2334 6.76729C40.0752 14.3246 39.7622 25.4146 32.4732 31.4963C25.2289 37.5779 13.7811 36.3705 6.9393 28.7685C0.0974679 21.1664 0.410493 10.1211 7.6995 4.0395C14.9438 -2.04212 26.3916 -0.834741 33.2334 6.76729Z" fill="url(#paint4_linear_1503_11821)"/>
                        <path opacity="0.33" d="M33.1449 6.67452C39.8973 14.1424 39.5395 25.0536 32.34 31.0905C25.1851 37.1274 13.8715 35.92 7.16381 28.4968C0.411412 21.0289 0.769148 10.1178 7.96872 4.08088C15.1236 -1.91131 26.4372 -0.748648 33.1449 6.71923V6.67452Z" fill="url(#paint5_linear_1503_11821)"/>
                        <path opacity="0.44" d="M33.0173 6.63774C39.6356 13.9715 39.2778 24.7485 32.1677 30.6959C25.0576 36.6434 13.9228 35.5255 7.30456 28.1918C0.686321 20.858 1.04407 10.081 8.15421 4.08882C15.2643 -1.85865 26.3991 -0.740705 33.0173 6.63774Z" fill="url(#paint6_linear_1503_11821)"/>
                        <path opacity="0.56" d="M32.8683 6.53228C39.3971 13.7766 38.9946 24.4194 31.9739 30.2775C24.9532 36.1802 13.9526 35.0623 7.42384 27.8627C0.895035 20.6184 1.29749 9.97555 8.31819 4.11751C15.3389 -1.78524 26.3395 -0.667297 32.8683 6.53228Z" fill="url(#paint7_linear_1503_11821)"/>
                        <path opacity="0.67" d="M32.7841 6.50271C39.2234 13.6128 38.7763 24.1215 31.845 29.9349C24.9137 35.7482 14.0473 34.7197 7.65264 27.6095C1.21327 20.4994 1.66046 9.9907 8.59172 4.17738C15.523 -1.63594 26.3447 -0.607433 32.7841 6.50271Z" fill="url(#paint8_linear_1503_11821)"/>
                        <path opacity="0.78" d="M32.6531 6.44803C38.9583 13.4687 38.5111 23.7986 31.6693 29.5224C24.7827 35.2911 14.0952 34.3073 7.78996 27.2866C1.48474 20.2659 1.8872 9.93602 8.77375 4.16742C15.6156 -1.60118 26.3032 -0.572674 32.6531 6.40331V6.44803Z" fill="url(#paint9_linear_1503_11821)"/>
                        <path opacity="0.89" d="M32.5153 6.35454C38.7311 13.2411 38.2392 23.4368 31.4868 29.1159C24.6897 34.8398 14.1363 33.856 7.92053 26.9695C1.70474 20.0829 2.15192 9.88725 8.99375 4.20808C15.7909 -1.47109 26.2996 -0.53201 32.5601 6.35454H32.5153Z" fill="url(#paint10_linear_1503_11821)"/>
                        <path d="M32.4294 6.30995C38.5557 13.0623 38.0639 23.1239 31.3115 28.7583C24.6038 34.3928 14.1845 33.4984 8.10289 26.7013C1.97654 19.9489 2.46844 9.88738 9.22083 4.25293C15.9285 -1.38152 26.3031 -0.442443 32.4294 6.30995Z" fill="url(#paint11_linear_1503_11821)"/>
                    </g>
                    <path opacity="0.05" d="M5.25792 12.7533C4.18469 18.343 5.57095 21.4286 6.37587 23.3067C7.09135 25.0507 4.453 23.3514 4.40828 23.3067C1.14388 18.1641 3.37977 12.1272 4.13998 11.6353C4.90018 11.1434 5.39208 12.1272 5.25792 12.7533Z" fill="white"/>
                    <path opacity="0.1" d="M5.18095 12.9369C4.19716 18.2136 5.44926 21.2097 6.25418 23.0879C6.96966 24.7871 4.46546 23.222 4.42075 23.0879C1.33521 18.1689 3.39224 12.4003 4.15244 11.8637C4.91265 11.3271 5.31511 12.3556 5.22567 12.9369H5.18095Z" fill="white"/>
                    <path opacity="0.15" d="M5.0461 13.1069C4.10703 18.1153 5.31441 21.022 6.07461 22.8554C6.74538 24.5547 4.42006 23.0343 4.33062 22.8554C1.42396 18.1153 3.34683 12.7045 4.06231 12.1231C4.73308 11.5418 5.13554 12.615 5.0461 13.1516V13.1069Z" fill="white"/>
                    <path opacity="0.2" d="M4.95547 13.2876C4.10583 17.983 5.17907 20.8002 5.93927 22.6337C6.61004 24.2882 4.41886 22.8572 4.32942 22.5889C1.60164 18.0277 3.39035 12.9299 4.0164 12.3038C4.64245 11.7225 5.04491 12.751 4.95547 13.2876Z" fill="white"/>
                    <path opacity="0.25" d="M4.86589 13.4628C4.06097 17.8899 5.08947 20.6177 5.80496 22.4064C6.47573 24.061 4.4187 22.6747 4.32927 22.3617C1.78035 17.9793 3.43491 13.1945 4.01625 12.5685C4.59758 11.9424 4.95532 13.0156 4.86589 13.4628Z" fill="white"/>
                    <path opacity="0.3" d="M4.73123 13.6435C3.97103 17.7576 4.91011 20.3959 5.62559 22.1846C6.25164 23.7945 4.37349 22.4977 4.23933 22.0952C1.86929 17.9364 3.3897 13.4647 3.92631 12.7939C4.46292 12.1231 4.82067 13.2411 4.73123 13.6435Z" fill="white"/>
                    <path opacity="0.35" d="M4.64137 13.8284C3.9706 17.6741 4.77552 20.223 5.49101 21.967C6.11706 23.5769 4.37306 22.3695 4.23891 21.8776C2.00301 17.8977 3.43399 13.7389 3.92588 13.0235C4.41778 12.308 4.7308 13.4706 4.68609 13.8284H4.64137Z" fill="white"/>
                    <path opacity="0.4" d="M4.55763 14.0067C3.93158 17.5841 4.69177 19.9989 5.36254 21.7429C5.98859 23.308 4.37875 22.1901 4.19988 21.6535C2.14285 17.8524 3.43967 14.0067 3.88685 13.2912C4.33403 12.5757 4.60234 13.6937 4.55763 14.0514V14.0067Z" fill="white"/>
                    <path opacity="0.44" d="M4.41365 14.1781C3.87703 17.4425 4.50309 19.8126 5.17385 21.5118C5.75519 23.077 4.32422 22.0037 4.14534 21.3777C2.26719 17.7556 3.42985 14.2676 3.83231 13.4626C4.23477 12.7024 4.50309 13.8651 4.45837 14.1781H4.41365Z" fill="white"/>
                    <path opacity="0.49" d="M4.33018 14.354C3.83828 17.3501 4.41962 19.586 5.04567 21.2853C5.627 22.8057 4.33018 21.8219 4.1066 21.1511C2.40732 17.7526 3.43583 14.5329 3.79357 13.728C4.15131 12.9231 4.3749 14.1304 4.33018 14.3988V14.354Z" fill="white"/>
                    <path opacity="0.54" d="M4.19018 14.5404C3.743 17.2234 4.19018 19.4146 4.86095 21.0692C5.44228 22.5896 4.27962 21.6505 4.05603 20.935C2.53562 17.7153 3.42998 14.8087 3.69829 13.959C4.01131 13.1094 4.23489 14.3615 4.19018 14.5404Z" fill="white"/>
                    <path opacity="0.59" d="M4.09705 14.7098C3.7393 17.1245 4.09705 19.1816 4.72309 20.8361C5.25971 22.3118 4.27592 21.5069 4.05233 20.6572C2.71079 17.6164 3.42628 15.0675 3.69459 14.1732C3.96289 13.3235 4.14177 14.5309 4.09705 14.7098Z" fill="white"/>
                    <path opacity="0.64" d="M4.01785 14.8897C3.70482 17.0362 4.01784 19.0038 4.59917 20.5689C5.13579 22.0446 4.28615 21.2844 4.01785 20.39C2.81046 17.5281 3.43651 15.2922 3.6601 14.3531C3.88369 13.4588 4.01785 14.7109 4.01785 14.845V14.8897Z" fill="white"/>
                    <path opacity="0.69" d="M3.87657 15.0819C3.65298 16.9154 3.87657 18.7935 4.41319 20.3587C4.9498 21.7896 4.23432 21.1189 3.96601 20.1798C2.9375 17.4967 3.42939 15.5738 3.60827 14.6348C3.78714 13.6957 3.92129 14.9925 3.92129 15.0819H3.87657Z" fill="white"/>
                    <path opacity="0.74" d="M3.79743 15.2487C3.61856 16.8138 3.70799 18.6025 4.28933 20.1229C4.78122 21.5539 4.24461 20.9279 3.93158 19.8993C3.08194 17.4399 3.43969 15.83 3.57384 14.8462C3.70799 13.8624 3.79743 15.204 3.79743 15.2487Z" fill="white"/>
                    <path opacity="0.79" d="M3.69678 15.4353C3.69678 16.7321 3.60735 18.3867 4.14396 19.9071C4.63586 21.2933 4.23339 20.7567 3.92037 19.6835C3.2496 17.4029 3.47319 16.1061 3.56262 15.0776C3.65206 14.0938 3.7415 15.4353 3.7415 15.4353H3.69678Z" fill="white"/>
                    <path style={{mixBlendMode: 'screen'}} d="M32.281 7.33785C38.139 14.6269 37.3341 24.9567 30.537 30.4123C23.7399 35.8679 13.4548 34.3922 7.64145 27.1031C1.78341 19.8141 2.58834 9.48431 9.38546 4.02873C16.1826 -1.42685 26.423 0.0488424 32.281 7.33785Z" fill="url(#paint12_linear_1503_11821)"/>
                    <g style={{mixBlendMode: 'overlay'}} opacity="0.56">
                        <path style={{mixBlendMode: 'screen'}} d="M29.6128 29.6432C33.5033 26.7813 35.8733 22.4437 36.6782 17.7483C34.8448 17.9272 32.9667 18.2849 31.0885 18.7321C22.9051 20.8338 16.1527 25.395 12.1729 30.8059C17.6284 33.7573 24.2914 33.5784 29.6128 29.6432Z" fill="url(#paint13_linear_1503_11821)"/>
                    </g>
                    <defs>
                        <radialGradient id="paint0_radial_1503_11821" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(38.4011 32.3064) rotate(-26.59) scale(49.9033 52.6155)">
                        <stop offset="0.32" stopColor="#A5A802"/>
                        <stop offset="0.33" stopColor="#9EAB05"/>
                        <stop offset="0.395833" stopColor="#A4B10B"/>
                        <stop offset="0.75" stopColor="#ADBB10"/>
                        <stop offset="1" stopColor="#B0CB0A"/>
                        </radialGradient>
                        <radialGradient id="paint1_radial_1503_11821" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(23.4614 -1.08339) rotate(9.69) scale(13.7284 13.7284)">
                        <stop offset="0.1" stopColor="#A9A9A9"/>
                        <stop offset="0.48" stopColor="#A9A9A9"/>
                        <stop offset="0.52" stopColor="#99A599"/>
                        <stop offset="0.69" stopColor="#589558"/>
                        <stop offset="0.83" stopColor="#288928"/>
                        <stop offset="0.94" stopColor="#0A820A"/>
                        <stop offset="1" stopColor="#008000"/>
                        </radialGradient>
                        <radialGradient id="paint2_radial_1503_11821" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(35.1237 35.2927) rotate(9.69) scale(34.1197 34.1197)">
                        <stop offset="0.08" stopColor="#2C2C2C"/>
                        <stop offset="0.12" stopColor="#434343"/>
                        <stop offset="0.23" stopColor="#787878"/>
                        <stop offset="0.34" stopColor="#A2A2A2"/>
                        <stop offset="0.43" stopColor="#C0C0C0"/>
                        <stop offset="0.51" stopColor="#D2D2D2"/>
                        <stop offset="0.57" stopColor="#D9D9D9"/>
                        <stop offset="0.66" stopColor="#D6D6D6"/>
                        <stop offset="0.73" stopColor="#CDCDCD"/>
                        <stop offset="0.79" stopColor="#BDBDBD"/>
                        <stop offset="0.84" stopColor="#A8A8A8"/>
                        <stop offset="0.89" stopColor="#8C8C8C"/>
                        <stop offset="0.93" stopColor="#696969"/>
                        <stop offset="0.97" stopColor="#414141"/>
                        <stop offset="0.99" stopColor="#2C2C2C"/>
                        </radialGradient>
                        <linearGradient id="paint3_linear_1503_11821" x1="28.5513" y1="38.2547" x2="13.3206" y2="-1.24785" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#768000"/>
                        <stop offset="0.44" stopColor="#768000"/>
                        <stop offset="0.58" stopColor="#6F8102"/>
                        <stop offset="0.73" stopColor="#768903"/>
                        <stop offset="1" stopColor="#809405"/>
                        </linearGradient>
                        <linearGradient id="paint4_linear_1503_11821" x1="29.2087" y1="39.8529" x2="14.0389" y2="0.546577" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#6E8000"/>
                        <stop offset="0.44" stopColor="#6E8000"/>
                        <stop offset="0.58" stopColor="#698203"/>
                        <stop offset="0.73" stopColor="#7F8A06"/>
                        <stop offset="1" stopColor="#82950B"/>
                        </linearGradient>
                        <linearGradient id="paint5_linear_1503_11821" x1="30.2066" y1="41.4016" x2="15.2301" y2="2.7722" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#6E8000"/>
                        <stop offset="0.44" stopColor="#6E8000"/>
                        <stop offset="0.58" stopColor="#718305"/>
                        <stop offset="0.73" stopColor="#718B09"/>
                        <stop offset="1" stopColor="#839610"/>
                        </linearGradient>
                        <linearGradient id="paint6_linear_1503_11821" x1="30.8069" y1="42.8402" x2="15.8802" y2="4.49162" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#6E8000"/>
                        <stop offset="0.44" stopColor="#6E8000"/>
                        <stop offset="0.58" stopColor="#7A8407"/>
                        <stop offset="0.74" stopColor="#7A8C0D"/>
                        <stop offset="1" stopColor="#859715"/>
                        </linearGradient>
                        <linearGradient id="paint7_linear_1503_11821" x1="31.2475" y1="44.2798" x2="16.4151" y2="6.17653" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#7D8000"/>
                        <stop offset="0.44" stopColor="#768000"/>
                        <stop offset="0.58" stopColor="#838508"/>
                        <stop offset="0.78" stopColor="#7E8F12"/>
                        <stop offset="1" stopColor="#87981B"/>
                        </linearGradient>
                        <linearGradient id="paint8_linear_1503_11821" x1="32.0037" y1="45.7213" x2="17.3664" y2="8.28235" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#BABE00"/>
                        <stop offset="0.369792" stopColor="#AAC004"/>
                        <stop offset="0.44" stopColor="#B2C155"/>
                        <stop offset="0.45" stopColor="#BABE01"/>
                        <stop offset="0.494592" stopColor="#BBB602"/>
                        <stop offset="0.494692" stopColor="#827D03"/>
                        <stop offset="0.494792" stopColor="#BEB706"/>
                        <stop offset="0.58" stopColor="#DCE014"/>
                        <stop offset="0.81" stopColor="#909217"/>
                        <stop offset="1" stopColor="#8F9920"/>
                        </linearGradient>
                        <linearGradient id="paint9_linear_1503_11821" x1="32.4478" y1="46.9553" x2="17.8635" y2="9.7928" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#C9CD05"/>
                        <stop offset="0.44" stopColor="#C4C800"/>
                        <stop offset="0.47" stopColor="#C2C607"/>
                        <stop offset="0.58" stopColor="#C8E417"/>
                        <stop offset="0.83" stopColor="#D7E62C"/>
                        <stop offset="1" stopColor="#D3E038"/>
                        </linearGradient>
                        <linearGradient id="paint10_linear_1503_11821" x1="32.7819" y1="48.198" x2="18.2512" y2="11.2705" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#CADB03"/>
                        <stop offset="0.44" stopColor="#B8C800"/>
                        <stop offset="0.49" stopColor="#C0C404"/>
                        <stop offset="0.58" stopColor="#C2C614"/>
                        <stop offset="0.85" stopColor="#C8CB2F"/>
                        <stop offset="1" stopColor="#C4C73C"/>
                        </linearGradient>
                        <linearGradient id="paint11_linear_1503_11821" x1="32.9901" y1="49.3235" x2="18.5954" y2="12.6852" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#B2B502"/>
                        <stop offset="0.44" stopColor="#C4C800"/>
                        <stop offset="0.62" stopColor="#C3C716"/>
                        <stop offset="0.86" stopColor="#BBD036"/>
                        <stop offset="1" stopColor="#C6D141"/>
                        </linearGradient>
                        <linearGradient id="paint12_linear_1503_11821" x1="14.701" y1="-3.60851" x2="30.1919" y2="38.0843" gradientUnits="userSpaceOnUse">
                        <stop offset="0.1" stopColor="white"/>
                        <stop offset="0.15" stopColor="#BCBCBC"/>
                        <stop offset="0.2" stopColor="#7A7A7A"/>
                        <stop offset="0.24" stopColor="#454545"/>
                        <stop offset="0.28" stopColor="#1F1F1F"/>
                        <stop offset="0.31" stopColor="#080808"/>
                        <stop offset="0.33"/>
                        <stop offset="0.48" stopColor="#020202"/>
                        <stop offset="0.57" stopColor="#0A0A0A"/>
                        <stop offset="0.64" stopColor="#181818"/>
                        <stop offset="0.71" stopColor="#2D2D2D"/>
                        <stop offset="0.76" stopColor="#474747"/>
                        <stop offset="0.82" stopColor="#676767"/>
                        <stop offset="0.87" stopColor="#8D8D8D"/>
                        <stop offset="0.92" stopColor="#B9B9B9"/>
                        <stop offset="0.96" stopColor="#EAEAEA"/>
                        <stop offset="0.98" stopColor="white"/>
                        </linearGradient>
                        <linearGradient id="paint13_linear_1503_11821" x1="37.7274" y1="43.8826" x2="24.1914" y2="20.2827" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#A9A9A9"/>
                        <stop offset="0.06" stopColor="#8E8E8E"/>
                        <stop offset="0.19" stopColor="#5C5C5C"/>
                        <stop offset="0.31" stopColor="#343434"/>
                        <stop offset="0.42" stopColor="#171717"/>
                        <stop offset="0.51" stopColor="#060606"/>
                        <stop offset="0.58"/>
                        <stop offset="0.69" stopColor="#020202"/>
                        <stop offset="0.76" stopColor="#0A0A0A"/>
                        <stop offset="0.81" stopColor="#181818"/>
                        <stop offset="0.86" stopColor="#2D2D2D"/>
                        <stop offset="0.9" stopColor="#474747"/>
                        <stop offset="0.94" stopColor="#676767"/>
                        <stop offset="0.98" stopColor="#8C8C8C"/>
                        <stop offset="1" stopColor="#A9A9A9"/>
                        </linearGradient>
                    </defs>
                </svg> : false}
                </div>
             </div>  : false}
            {cloudLoaded  ?  <div className="card__cloud">
                <div className="card__wrapper-svg">
                        <div className="card__wrapper-rotate">
                            <svg xmlns="http://www.w3.org/2000/svg" width="255" height="265" viewBox="0 0 255 265" fill="none">
                            <path d="M19.666 19.5199L0.093192 155.367C-0.14637 157.03 0.0691529 158.686 0.693089 160.163C1.27684 161.669 2.26563 163.019 3.59945 164.048L108.613 245.571C110.719 247.213 113.003 248.618 115.41 249.742C118.591 251.228 121.982 252.243 125.495 252.749L132.286 253.727L133.082 253.842L202.456 263.849C214.329 265.56 225.341 257.322 227.065 245.439L254.725 53.3752C256.436 41.5023 248.198 30.4908 236.325 28.7801L44.2745 1.10956C32.3882 -0.591049 21.3767 7.64697 19.666 19.5199Z" fill="url(#paint0_linear_1503_12148)"/>
                            <defs>
                                <linearGradient id="paint0_linear_1503_12148" x1="224.939" y1="8.84108" x2="60.1124" y2="209.48" gradientUnits="userSpaceOnUse">
                                <stop offset="0.18" stopColor="#F7D29E"/>
                                <stop offset="0.435" stopColor="#FFD28D"/>
                                <stop offset="0.52" stopColor="#FFCA7B"/>
                                <stop offset="1" stopColor="#FFB04A"/>
                                </linearGradient>
                            </defs>
                            </svg>
                            <svg className='card__wrapped-cloud' xmlns="http://www.w3.org/2000/svg" width="133" height="115" viewBox="0 0 133 115" fill="none">
                            <path d="M0.558738 13.1355C-0.402867 19.8096 4.03731 19.768 9.69485 17.4159L46.7635 2.03191C57.8376 -2.57564 70.5494 2.6861 75.1435 13.7702L109.111 95.5636C109.111 95.5636 109.148 95.6406 109.165 95.6908C115.721 111.324 126.99 113.904 132.286 114.727L125.495 113.749C121.982 113.242 118.59 112.228 115.41 110.742C113.002 109.618 110.719 108.213 108.613 106.571L3.59936 25.0475C2.26554 24.0187 1.27675 22.6691 0.692997 21.1627C0.0690611 19.6863 -0.146452 18.0298 0.0931092 16.3671L0.558738 13.1355Z" fill="url(#paint0_linear_1503_12149)"/>
                            <defs>
                                <linearGradient id="paint0_linear_1503_12149" x1="94.0382" y1="27.8828" x2="66.3034" y2="64.0934" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#E4E1ED"/>
                                <stop offset="0.305" stopColor="#ECEBF4"/>
                                <stop offset="0.545" stopColor="#ECEBF4"/>
                                <stop offset="1" stopColor="#C8C0D9"/>
                                </linearGradient>
                            </defs>
                            </svg>
                            <span className="name-card">Cloud</span>
                        </div>
                       {cloudButton ? <svg className='button' xmlns="http://www.w3.org/2000/svg" width="47" height="49" viewBox="0 0 47 49" fill="none">
                        <g style={{mixBlendMode:'multiply'}} opacity="0">
                        <path opacity="0.06" d="M3.22952 35.3099C8.90869 46.5788 22.5924 51.14 33.8613 45.5056C45.0854 39.8711 49.6467 26.1427 43.9675 14.8291C38.2883 3.56021 24.6047 -1.00101 13.3358 4.63344C2.11158 10.3126 -2.40493 23.9963 3.22952 35.3099Z" fill="#F6F6F6"/>
                        <path opacity="0.12" d="M3.90973 34.8079C9.45475 45.8085 22.8254 50.2802 33.826 44.7352C44.8266 39.2349 49.2089 25.8196 43.6639 14.7743C38.1189 3.77367 24.7483 -0.69811 13.7477 4.8469C2.74707 10.3919 -1.63528 23.7626 3.90973 34.8079Z" fill="#EDEDED"/>
                        <path opacity="0.19" d="M4.57434 34.2731C9.9852 45.0501 23.0428 49.3878 33.7751 43.9769C44.5074 38.5661 48.8003 25.5084 43.3894 14.7314C37.9786 3.99916 24.921 -0.383194 14.1887 5.02767C3.45638 10.4385 -0.836525 23.4961 4.57434 34.2731Z" fill="#E3E4E4"/>
                        <path opacity="0.25" d="M5.25064 33.7856C10.5274 44.2942 23.272 48.4977 33.7359 43.2657C44.1999 37.989 48.4034 25.2444 43.1267 14.7357C37.85 4.22705 25.1054 -0.0211453 14.6414 5.25556C4.17743 10.5323 -0.026062 23.2769 5.25064 33.7856Z" fill="#DADBDB"/>
                        <path opacity="0.31" d="M5.91133 33.2408C11.0539 43.4812 23.4855 47.5952 33.6811 42.4526C43.8768 37.3101 47.9908 24.8785 42.8483 14.6381C37.7057 4.39775 25.2742 0.283709 15.0785 5.38154C4.88282 10.5241 0.76878 22.9557 5.91133 33.196V33.2408Z" fill="#D1D1D2"/>
                        <path opacity="0.38" d="M6.5937 32.7058C11.6021 42.6778 23.7206 46.7024 33.6927 41.694C43.6201 36.6856 47.6447 24.5671 42.6363 14.595C37.6279 4.62294 25.5093 0.598335 15.5373 5.60673C5.6099 10.6151 1.5853 22.7337 6.5937 32.7058Z" fill="#C8C8C9"/>
                        <path opacity="0.44" d="M7.29723 32.214C12.1715 41.9178 23.977 45.8529 33.6807 40.9787C43.3845 36.1045 47.275 24.2989 42.356 14.5952C37.4818 4.89139 25.6763 0.956215 15.9725 5.83046C6.31342 10.7047 2.42298 22.5102 7.29723 32.2587V32.214Z" fill="#BEBFC0"/>
                        <path opacity="0.5" d="M7.97366 31.6832C12.7138 41.1187 24.2063 44.9644 33.6417 40.2243C43.0772 35.4842 46.8335 23.9917 42.0934 14.5562C37.3533 5.12078 25.8608 1.27504 16.4253 6.01514C6.98986 10.7552 3.23357 22.2477 7.97366 31.7279V31.6832Z" fill="#B5B6B8"/>
                        <path opacity="0.56" d="M8.64218 31.1466C13.2481 40.3137 24.4276 44.0253 33.5947 39.4194C42.7619 34.8134 46.4288 23.634 41.8228 14.4668C37.2169 5.25492 26.0374 1.54334 16.8703 6.14928C7.70311 10.7552 4.03624 21.9347 8.64218 31.1466Z" fill="#ACADAF"/>
                        <path opacity="0.62" d="M9.31066 30.6562C13.7824 39.5997 24.6489 43.1772 33.5477 38.7054C42.4466 34.2336 46.024 23.3672 41.5522 14.4236C37.0805 5.48002 26.214 1.90259 17.3152 6.37438C8.41631 10.8462 4.83887 21.7126 9.31066 30.6562Z" fill="#A3A4A6"/>
                        <path opacity="0.69" d="M9.97915 30.1097C14.3168 38.785 24.8702 42.273 33.5007 37.9353C42.1313 33.5977 45.6193 23.0443 41.2817 14.369C36.944 5.69378 26.3906 2.20578 17.76 6.54341C9.1295 10.881 5.64151 21.4345 10.0239 30.1097H9.97915Z" fill="#999B9D"/>
                        <path opacity="0.75" d="M10.6553 29.5789C14.8588 37.9859 25.0992 41.3844 33.4614 37.1809C41.8684 32.9775 45.2222 22.7371 40.974 14.3301C36.7258 5.92317 26.5302 2.52461 18.1232 6.72809C9.76097 10.9316 6.36242 21.172 10.6106 29.5789H10.6553Z" fill="#909294"/>
                        <path opacity="0.81" d="M11.3299 29.0889C15.444 37.2276 25.3266 40.5367 33.4653 36.4227C41.6039 32.3533 44.8683 22.426 40.7543 14.2873C36.6402 6.14866 26.7576 2.83954 18.6189 6.90887C10.525 10.9782 7.26059 20.9056 11.3299 29.0442V29.0889Z" fill="#87888B"/>
                        <path opacity="0.88" d="M12.0024 28.5581C15.9823 36.4285 25.5519 39.6482 33.4223 35.6683C41.2926 31.7331 44.4229 22.1188 40.4877 14.2484C36.5078 6.37808 26.9382 3.1584 19.0679 7.09357C11.1975 11.0287 8.06728 20.6431 12.0024 28.5581Z" fill="#7E7F82"/>
                        <path opacity="0.94" d="M12.6709 28.0154C16.5167 35.6175 25.7733 38.703 33.3753 34.902C40.9773 31.101 44.0181 21.7996 40.2171 14.1976C36.3714 6.55085 27.1148 3.51004 19.5128 7.31106C11.9107 11.1121 8.8699 20.4134 12.6709 28.0601V28.0154Z" fill="#747679"/>
                        <path d="M13.3357 27.5293C17.0473 34.9078 25.9909 37.8591 33.3246 34.1476C40.6583 30.4807 43.6097 21.4924 39.8981 14.1587C36.1865 6.78024 27.243 3.82886 19.9093 7.49572C12.5755 11.2073 9.62414 20.1509 13.3357 27.5293Z" fill="#6B6D70"/>
                        </g>
                        <path d="M2.68594 11.0021C-2.59077 20.7058 0.673634 32.3325 10.0197 36.9831C19.321 41.6338 31.1712 37.5645 36.4479 27.9054C41.7246 18.2016 38.4602 6.575 29.1142 1.92434C19.7682 -2.72631 7.96265 1.34301 2.64123 11.0021H2.68594Z" fill="url(#paint0_radial_1503_11629)"/>
                        <g style={{mixBlendMode:'screen'}} opacity="0.54">
                            <path style={{mixBlendMode:'screen'}} d="M27.6388 2.10259C19.3213 -0.938227 9.2151 1.61069 3.13347 11.4486C1.25532 14.4447 0.763412 14.8919 2.82043 11.1356C8.09714 1.43182 19.8132 -2.68222 29.1145 2.01315C31.887 3.3994 29.8747 2.90751 27.6388 2.10259Z" fill="url(#paint1_radial_1503_11629)"/>
                        </g>
                        <g style={{mixBlendMode:'soft-light'}} opacity="0.61">
                            <path d="M1.16577 21.1057C2.06013 26.293 5.2351 30.8989 10.1988 33.3584C19.0082 37.7408 30.1877 33.9397 35.1513 24.7726C38.1027 19.3617 38.2369 13.2801 36.001 8.31641C39.3548 13.6825 39.7126 20.9268 36.2693 27.2321C31.1267 36.5781 19.679 40.5133 10.6907 35.9968C4.83264 33.0901 1.43409 27.3215 1.2105 21.061L1.16577 21.1057Z" fill="url(#paint2_radial_1503_11629)"/>
                        </g>
                        <g style={{mixBlendMode:'overlay'}}>
                            <path opacity="0.11" d="M6.20667 6.80558C-0.724602 14.4971 -0.411587 25.7212 6.87742 31.8476C14.2112 38.0186 25.7931 36.7218 32.7244 29.0304C39.6556 21.3389 39.3873 10.1147 32.0536 3.94364C24.7199 -2.1827 13.1379 -0.930604 6.20667 6.76087V6.80558Z" fill="url(#paint3_linear_1503_11629)"/>
                            <path opacity="0.22" d="M6.34765 6.7651C-0.494179 14.3224 -0.181145 25.4124 7.10786 31.4941C14.3522 37.5757 25.7999 36.3683 32.6418 28.7663C39.4836 21.1642 39.1706 10.1189 31.8816 4.03731C24.6373 -2.04432 13.1895 -0.836939 6.34765 6.7651Z" fill="url(#paint4_linear_1503_11629)"/>
                            <path opacity="0.33" d="M6.43618 6.67232C-0.316219 14.1402 0.041523 25.0514 7.2411 31.0883C14.396 37.1252 25.7096 35.9178 32.4172 28.4946C39.1696 21.0268 38.8119 10.1156 31.6123 4.07868C24.4575 -1.91351 13.1439 -0.750845 6.43618 6.71704V6.67232Z" fill="url(#paint5_linear_1503_11629)"/>
                            <path opacity="0.44" d="M6.56371 6.63554C-0.0545273 13.9693 0.303226 24.7463 7.41336 30.6938C14.5235 36.6412 25.6582 35.5233 32.2765 28.1896C38.8947 20.8558 38.537 10.0788 31.4268 4.08663C24.3167 -1.86085 13.182 -0.742902 6.56371 6.63554Z" fill="url(#paint6_linear_1503_11629)"/>
                            <path opacity="0.56" d="M6.71276 6.53008C0.183952 13.7744 0.586414 24.4172 7.60712 30.2753C14.6278 36.178 25.6284 35.0601 32.1572 27.8605C38.686 20.6162 38.2836 9.97336 31.2629 4.11532C24.2422 -1.78744 13.2416 -0.669494 6.71276 6.53008Z" fill="url(#paint7_linear_1503_11629)"/>
                            <path opacity="0.67" d="M6.79698 6.50051C0.357609 13.6106 0.804783 24.1193 7.73605 29.9327C14.6673 35.746 25.5338 34.7175 31.9284 27.6073C38.3678 20.4972 37.9206 9.9885 30.9893 4.17518C24.0581 -1.63814 13.2364 -0.609631 6.79698 6.50051Z" fill="url(#paint8_linear_1503_11629)"/>
                            <path opacity="0.78" d="M6.92797 6.44583C0.622753 13.4665 1.06993 23.7964 7.91177 29.5203C14.7983 35.2889 25.4859 34.3051 31.7911 27.2844C38.0963 20.2637 37.6939 9.93383 30.8073 4.16522C23.9655 -1.60338 13.2779 -0.574871 6.92797 6.40111V6.44583Z" fill="url(#paint9_linear_1503_11629)"/>
                            <path opacity="0.89" d="M7.06571 6.35234C0.849926 13.2389 1.34182 23.4346 8.09422 29.1137C14.8913 34.8376 25.4447 33.8538 31.6605 26.9673C37.8763 20.0807 37.4291 9.88505 30.5873 4.20589C23.7902 -1.47328 13.2815 -0.534207 7.021 6.35234H7.06571Z" fill="url(#paint10_linear_1503_11629)"/>
                            <path d="M7.15165 6.30776C1.02531 13.0602 1.5172 23.1217 8.2696 28.7561C14.9773 34.3906 25.3965 33.4962 31.4782 26.6991C37.6045 19.9467 37.1126 9.88519 30.3602 4.25074C23.6525 -1.38371 13.278 -0.44464 7.15165 6.30776Z" fill="url(#paint11_linear_1503_11629)"/>
                        </g>
                        <path opacity="0.05" d="M34.3231 12.7511C35.3964 18.3408 34.0101 21.4264 33.2052 23.3045C32.4897 25.0485 35.1281 23.3492 35.1728 23.3045C38.4372 18.162 36.2013 12.125 35.4411 11.6331C34.6809 11.1412 34.189 12.125 34.3231 12.7511Z" fill="white"/>
                        <path opacity="0.1" d="M34.4001 12.9347C35.3839 18.2114 34.1318 21.2075 33.3269 23.0857C32.6114 24.7849 35.1156 23.2198 35.1603 23.0857C38.2458 18.1667 36.1888 12.3981 35.4286 11.8615C34.6684 11.3249 34.2659 12.3534 34.3554 12.9347H34.4001Z" fill="white"/>
                        <path opacity="0.15" d="M34.535 13.1047C35.474 18.1131 34.2666 21.0198 33.5064 22.8532C32.8357 24.5525 35.161 23.0321 35.2504 22.8532C38.1571 18.1131 36.2342 12.7023 35.5187 12.1209C34.848 11.5396 34.4455 12.6128 34.535 13.1494V13.1047Z" fill="white"/>
                        <path opacity="0.2" d="M34.6256 13.2854C35.4752 17.9808 34.402 20.798 33.6418 22.6315C32.971 24.286 35.1622 22.855 35.2516 22.5867C37.9794 18.0255 36.1907 12.9277 35.5647 12.3016C34.9386 11.7203 34.5361 12.7488 34.6256 13.2854Z" fill="white"/>
                        <path opacity="0.25" d="M34.7152 13.4606C35.5201 17.8877 34.4916 20.6155 33.7761 22.4042C33.1053 24.0588 35.1623 22.6725 35.2518 22.3595C37.8007 17.9771 36.1461 13.1923 35.5648 12.5663C34.9835 11.9402 34.6257 13.0134 34.7152 13.4606Z" fill="white"/>
                        <path opacity="0.3" d="M34.8498 13.6413C35.61 17.7554 34.6709 20.3937 33.9555 22.1824C33.3294 23.7923 35.2076 22.4955 35.3417 22.093C37.7118 17.9342 36.1914 13.4625 35.6547 12.7917C35.1181 12.1209 34.7604 13.2389 34.8498 13.6413Z" fill="white"/>
                        <path opacity="0.35" d="M34.9397 13.8262C35.6105 17.6719 34.8055 20.2208 34.09 21.9648C33.464 23.5747 35.208 22.3673 35.3421 21.8754C37.578 17.8955 36.1471 13.7367 35.6552 13.0213C35.1633 12.3058 34.8503 13.4684 34.895 13.8262H34.9397Z" fill="white"/>
                        <path opacity="0.4" d="M35.0234 14.0045C35.6495 17.5819 34.8893 19.9967 34.2185 21.7407C33.5925 23.3058 35.2023 22.1879 35.3812 21.6513C37.4382 17.8502 36.1414 14.0045 35.6942 13.289C35.247 12.5735 34.9787 13.6915 35.0234 14.0492V14.0045Z" fill="white"/>
                        <path opacity="0.44" d="M35.1674 14.1759C35.704 17.4403 35.078 19.8104 34.4072 21.5097C33.8259 23.0748 35.2568 22.0015 35.4357 21.3755C37.3139 17.7534 36.1512 14.2654 35.7487 13.4604C35.3463 12.7002 35.078 13.8629 35.1227 14.1759H35.1674Z" fill="white"/>
                        <path opacity="0.49" d="M35.2509 14.3518C35.7428 17.3479 35.1614 19.5838 34.5354 21.2831C33.9541 22.8035 35.2509 21.8197 35.4745 21.149C37.1737 17.7504 36.1452 14.5307 35.7875 13.7258C35.4297 12.9209 35.2062 14.1282 35.2509 14.3966V14.3518Z" fill="white"/>
                        <path opacity="0.54" d="M35.3909 14.5382C35.8381 17.2212 35.3909 19.4124 34.7201 21.067C34.1388 22.5874 35.3014 21.6483 35.525 20.9328C37.0454 17.7131 36.1511 14.8065 35.8828 13.9568C35.5697 13.1072 35.3462 14.3593 35.3909 14.5382Z" fill="white"/>
                        <path opacity="0.59" d="M35.484 14.7076C35.8417 17.1223 35.484 19.1794 34.858 20.8339C34.3213 22.3096 35.3051 21.5047 35.5287 20.655C36.8703 17.6142 36.1548 15.0653 35.8865 14.171C35.6182 13.3213 35.4393 14.5287 35.484 14.7076Z" fill="white"/>
                        <path opacity="0.64" d="M35.5632 14.8875C35.8762 17.034 35.5632 19.0016 34.9819 20.5667C34.4453 22.0424 35.2949 21.2822 35.5632 20.3878C36.7706 17.5259 36.1445 15.29 35.921 14.3509C35.6974 13.4566 35.5632 14.7087 35.5632 14.8428V14.8875Z" fill="white"/>
                        <path opacity="0.69" d="M35.7045 15.0798C35.9281 16.9132 35.7045 18.7913 35.1679 20.3565C34.6313 21.7874 35.3467 21.1167 35.615 20.1776C36.6436 17.4945 36.1517 15.5716 35.9728 14.6326C35.7939 13.6935 35.6598 14.9903 35.6598 15.0798H35.7045Z" fill="white"/>
                        <path opacity="0.74" d="M35.7836 15.2465C35.9625 16.8116 35.8731 18.6003 35.2917 20.1207C34.7998 21.5517 35.3364 20.9257 35.6495 19.8971C36.4991 17.4377 36.1414 15.8278 36.0072 14.844C35.8731 13.8602 35.7836 15.2018 35.7836 15.2465Z" fill="white"/>
                        <path opacity="0.79" d="M35.8843 15.4331C35.8843 16.7299 35.9737 18.3845 35.4371 19.9049C34.9452 21.2911 35.3477 20.7545 35.6607 19.6813C36.3315 17.4007 36.1079 16.1039 36.0184 15.0754C35.929 14.0916 35.8396 15.4331 35.8396 15.4331H35.8843Z" fill="white"/>
                        <path style={{mixBlendMode:'screen'}} d="M7.30005 7.33566C1.44202 14.6247 2.24695 24.9545 9.04407 30.4101C15.8412 35.8657 26.1263 34.39 31.9396 27.101C37.7976 19.8119 36.9927 9.48211 30.1956 4.02654C23.3985 -1.42904 13.1581 0.0466452 7.30005 7.33566Z" fill="url(#paint12_linear_1503_11629)"/>
                        <g style={{mixBlendMode:'overlay'}} opacity="0.56">
                            <path style={{mixBlendMode:'screen'}} d="M9.96824 29.641C6.07779 26.7791 3.70773 22.4415 2.90281 17.7461C4.73624 17.925 6.61439 18.2827 8.49254 18.7299C16.6759 20.8316 23.4283 25.3928 27.4082 30.8037C21.9526 33.7551 15.2897 33.5762 9.96824 29.641Z" fill="url(#paint13_linear_1503_11629)"/>
                        </g>
                        <defs>
                            <radialGradient id="paint0_radial_1503_11629" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(1.17998 32.3042) rotate(-153.41) scale(49.9033 52.6155)">
                            <stop offset="0.32" stopColor="#800073"/>
                            <stop offset="0.33" stopColor="#760080"/>
                            <stop offset="0.40625" stopColor="#80006C"/>
                            <stop offset="0.75" stopColor="#800073"/>
                            <stop offset="1" stopColor="#760080"/>
                            </radialGradient>
                            <radialGradient id="paint1_radial_1503_11629" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(16.1197 -1.08559) rotate(170.31) scale(13.7284 13.7284)">
                            <stop offset="0.1" stopColor="#A9A9A9"/>
                            <stop offset="0.48" stopColor="#A9A9A9"/>
                            <stop offset="0.52" stopColor="#99A599"/>
                            <stop offset="0.69" stopColor="#905895"/>
                            <stop offset="0.83" stopColor="#89287F"/>
                            <stop offset="0.94" stopColor="#820A76"/>
                            <stop offset="1" stopColor="#7D0080"/>
                            </radialGradient>
                            <radialGradient id="paint2_radial_1503_11629" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(4.4574 35.2905) rotate(170.31) scale(34.1197 34.1197)">
                            <stop offset="0.08" stopColor="#2C2C2C"/>
                            <stop offset="0.12" stopColor="#434343"/>
                            <stop offset="0.23" stopColor="#787878"/>
                            <stop offset="0.34" stopColor="#A2A2A2"/>
                            <stop offset="0.43" stopColor="#C0C0C0"/>
                            <stop offset="0.51" stopColor="#D2D2D2"/>
                            <stop offset="0.57" stopColor="#D9D9D9"/>
                            <stop offset="0.66" stopColor="#D6D6D6"/>
                            <stop offset="0.73" stopColor="#CDCDCD"/>
                            <stop offset="0.79" stopColor="#BDBDBD"/>
                            <stop offset="0.84" stopColor="#A8A8A8"/>
                            <stop offset="0.89" stopColor="#8C8C8C"/>
                            <stop offset="0.93" stopColor="#696969"/>
                            <stop offset="0.97" stopColor="#414141"/>
                            <stop offset="0.99" stopColor="#2C2C2C"/>
                            </radialGradient>
                            <linearGradient id="paint3_linear_1503_11629" x1="11.0298" y1="38.2525" x2="26.2605" y2="-1.25005" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#800064"/>
                            <stop offset="0.44" stopColor="#800073"/>
                            <stop offset="0.58" stopColor="#7E0281"/>
                            <stop offset="0.73" stopColor="#89036C"/>
                            <stop offset="1" stopColor="#94056C"/>
                            </linearGradient>
                            <linearGradient id="paint4_linear_1503_11629" x1="10.3723" y1="39.8507" x2="25.5422" y2="0.54438" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#660080"/>
                            <stop offset="0.44" stopColor="#760080"/>
                            <stop offset="0.58" stopColor="#7F0382"/>
                            <stop offset="0.73" stopColor="#8A0675"/>
                            <stop offset="1" stopColor="#950B87"/>
                            </linearGradient>
                            <linearGradient id="paint5_linear_1503_11629" x1="9.37447" y1="41.3994" x2="24.3509" y2="2.77" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#80007B"/>
                            <stop offset="0.44" stopColor="#80005C"/>
                            <stop offset="0.58" stopColor="#83057E"/>
                            <stop offset="0.73" stopColor="#8B097E"/>
                            <stop offset="1" stopColor="#961081"/>
                            </linearGradient>
                            <linearGradient id="paint6_linear_1503_11629" x1="8.77413" y1="42.838" x2="23.7009" y2="4.48942" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#760080"/>
                            <stop offset="0.44" stopColor="#7D0080"/>
                            <stop offset="0.58" stopColor="#820784"/>
                            <stop offset="0.74" stopColor="#8C0D87"/>
                            <stop offset="1" stopColor="#941597"/>
                            </linearGradient>
                            <linearGradient id="paint7_linear_1503_11629" x1="8.33355" y1="44.2776" x2="23.1659" y2="6.17434" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#7D0080"/>
                            <stop offset="0.44" stopColor="#800073"/>
                            <stop offset="0.58" stopColor="#850879"/>
                            <stop offset="0.78" stopColor="#8F1283"/>
                            <stop offset="1" stopColor="#981B75"/>
                            </linearGradient>
                            <linearGradient id="paint8_linear_1503_11629" x1="7.57732" y1="45.7191" x2="22.2147" y2="8.28015" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#800073"/>
                            <stop offset="0.44" stopColor="#7D0080"/>
                            <stop offset="0.45" stopColor="#80007B"/>
                            <stop offset="0.58" stopColor="#840A86"/>
                            <stop offset="0.81" stopColor="#921786"/>
                            <stop offset="1" stopColor="#8F2099"/>
                            </linearGradient>
                            <linearGradient id="paint9_linear_1503_11629" x1="7.13323" y1="46.9531" x2="21.7175" y2="9.7906" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#80006C"/>
                            <stop offset="0.44" stopColor="#6E0080"/>
                            <stop offset="0.47" stopColor="#7E0381"/>
                            <stop offset="0.520833" stopColor="#84077F"/>
                            <stop offset="0.58" stopColor="#870C82"/>
                            <stop offset="0.83" stopColor="#941D88"/>
                            <stop offset="1" stopColor="#9A2580"/>
                            </linearGradient>
                            <linearGradient id="paint10_linear_1503_11629" x1="6.79913" y1="48.1958" x2="21.3298" y2="11.2683" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#800073"/>
                            <stop offset="0.44" stopColor="#7D0080"/>
                            <stop offset="0.49" stopColor="#800583"/>
                            <stop offset="0.58" stopColor="#770D88"/>
                            <stop offset="0.85" stopColor="#932295"/>
                            <stop offset="1" stopColor="#922B9B"/>
                            </linearGradient>
                            <linearGradient id="paint11_linear_1503_11629" x1="6.59094" y1="49.3213" x2="20.9856" y2="12.683" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#570080"/>
                            <stop offset="0.44" stopColor="#6E0080"/>
                            <stop offset="0.62" stopColor="#80128A"/>
                            <stop offset="0.86" stopColor="#972785"/>
                            <stop offset="1" stopColor="#9C3098"/>
                            </linearGradient>
                            <linearGradient id="paint12_linear_1503_11629" x1="24.8801" y1="-3.61071" x2="9.38916" y2="38.0821" gradientUnits="userSpaceOnUse">
                            <stop offset="0.1" stopColor="white"/>
                            <stop offset="0.15" stopColor="#BCBCBC"/>
                            <stop offset="0.2" stopColor="#7A7A7A"/>
                            <stop offset="0.24" stopColor="#454545"/>
                            <stop offset="0.28" stopColor="#1F1F1F"/>
                            <stop offset="0.31" stopColor="#080808"/>
                            <stop offset="0.33"/>
                            <stop offset="0.48" stopColor="#020202"/>
                            <stop offset="0.57" stopColor="#0A0A0A"/>
                            <stop offset="0.64" stopColor="#181818"/>
                            <stop offset="0.71" stopColor="#2D2D2D"/>
                            <stop offset="0.76" stopColor="#474747"/>
                            <stop offset="0.82" stopColor="#676767"/>
                            <stop offset="0.87" stopColor="#8D8D8D"/>
                            <stop offset="0.92" stopColor="#B9B9B9"/>
                            <stop offset="0.96" stopColor="#EAEAEA"/>
                            <stop offset="0.98" stopColor="white"/>
                            </linearGradient>
                            <linearGradient id="paint13_linear_1503_11629" x1="1.85364" y1="43.8804" x2="15.3897" y2="20.2805" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#A9A9A9"/>
                            <stop offset="0.06" stopColor="#8E8E8E"/>
                            <stop offset="0.19" stopColor="#5C5C5C"/>
                            <stop offset="0.31" stopColor="#343434"/>
                            <stop offset="0.42" stopColor="#171717"/>
                            <stop offset="0.51" stopColor="#060606"/>
                            <stop offset="0.58"/>
                            <stop offset="0.69" stopColor="#020202"/>
                            <stop offset="0.76" stopColor="#0A0A0A"/>
                            <stop offset="0.81" stopColor="#181818"/>
                            <stop offset="0.86" stopColor="#2D2D2D"/>
                            <stop offset="0.9" stopColor="#474747"/>
                            <stop offset="0.94" stopColor="#676767"/>
                            <stop offset="0.98" stopColor="#8C8C8C"/>
                            <stop offset="1" stopColor="#A9A9A9"/>
                            </linearGradient>
                        </defs>
                    </svg> : false}
                </div>
             </div>  : false}
            {serviceLoaded  ?  <div className="card__service">
                <div className="card__wrapper-svg">
                    <div className="card__wrapper-rotate">
                        <svg xmlns="http://www.w3.org/2000/svg" width="300" height="255" viewBox="0 0 300 255" fill="none">
                        <path d="M2.68824 108.97L57.7372 234.697C58.411 236.236 59.4681 237.529 60.7771 238.454C62.0678 239.426 63.6198 240.051 65.2957 240.221L197.516 254.067C200.172 254.35 202.853 254.339 205.491 254.023C208.977 253.607 212.393 252.68 215.644 251.256L221.929 248.504L222.666 248.182L286.878 220.08C297.866 215.268 302.874 202.46 298.069 191.457L220.229 13.7078C215.418 2.7195 202.61 -2.28801 191.622 2.5232L13.879 80.3472C2.88453 85.174 -2.12297 97.9819 2.68824 108.97Z" fill="url(#paint0_linear_1503_12153)"/>
                            <defs>
                                <linearGradient id="paint0_linear_1503_12153" x1="171.429" y1="-8.40719" x2="137.274" y2="248.998" gradientUnits="userSpaceOnUse">
                                <stop offset="0.18" stopColor="#9EC2F7"/>
                                <stop offset="0.435" stopColor="#8DBBFF"/>
                                <stop offset="0.52" stopColor="#7BB0FF"/>
                                <stop offset="1" stopColor="#80B7FF"/>
                                </linearGradient>
                            </defs>
                        </svg>
                        <svg className='card__wrapped-service' xmlns="http://www.w3.org/2000/svg" width="166" height="67" viewBox="0 0 166 67" fill="none">
                        <path d="M0.428025 43.7061C3.13255 49.883 6.88248 47.505 10.4474 42.5218L33.8196 9.89529C40.7958 0.138362 54.3704 -2.09889 64.1213 4.89286L136.132 56.4528C136.132 56.4528 136.204 56.4988 136.244 56.5327C150.062 66.354 160.997 62.5995 165.929 60.5043L159.644 63.2563C156.393 64.6797 152.977 65.6073 149.491 66.0229C146.853 66.3387 144.172 66.3503 141.516 66.0666L9.296 52.2208C7.62012 52.0506 6.06808 51.4259 4.77739 50.4542C3.46842 49.5293 2.41132 48.2358 1.73757 46.697L0.428025 43.7061Z" fill="url(#paint0_linear_1503_12154)"/>
                            <defs>
                                <linearGradient id="paint0_linear_1503_12154" x1="87.618" y1="6.91206" x2="83.1632" y2="52.3057" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#E4E1ED"/>
                                <stop offset="0.305" stopColor="#ECEBF4"/>
                                <stop offset="0.545" stopColor="#ECEBF4"/>
                                <stop offset="1" stopColor="#C8C0D9"/>
                                </linearGradient>
                            </defs>
                        </svg>
                        <span className="name-card">IM Service</span>
                    </div>
                   {serviceButton ? <svg className='button' xmlns="http://www.w3.org/2000/svg" width="48" height="50" viewBox="0 0 48 50" fill="none">
                        <g style={{mixBlendMode:'multiply'}} opacity="0">
                            <path opacity="0.06" d="M3.58792 36.3104C9.26709 47.5793 22.9508 52.1405 34.2197 46.506C45.4438 40.8716 50.0051 27.1432 44.3259 15.8296C38.6467 4.5607 24.9631 -0.000522852 13.6942 5.63393C2.46998 11.3131 -2.04653 24.9968 3.58792 36.3104Z" fill="#F6F6F6"/>
                            <path opacity="0.12" d="M4.26813 35.8084C9.81314 46.8089 23.1838 51.2807 34.1844 45.7357C45.185 40.2354 49.5673 26.8201 44.0223 15.7748C38.4773 4.77416 25.1067 0.302379 14.1061 5.84739C3.10547 11.3924 -1.27688 24.763 4.26813 35.8084Z" fill="#EDEDED"/>
                            <path opacity="0.19" d="M4.93274 35.2736C10.3436 46.0506 23.4012 50.3883 34.1335 44.9774C44.8658 39.5665 49.1587 26.5089 43.7478 15.7319C38.337 4.99964 25.2794 0.617294 14.5471 6.02815C3.81478 11.439 -0.478127 24.4966 4.93274 35.2736Z" fill="#E3E4E4"/>
                            <path opacity="0.25" d="M5.60904 34.786C10.8857 45.2947 23.6304 49.4982 34.0943 44.2662C44.5583 38.9895 48.7618 26.2449 43.4851 15.7362C38.2084 5.22754 25.4638 0.979343 14.9998 6.25605C4.53583 11.5328 0.332336 24.2773 5.60904 34.786Z" fill="#DADBDB"/>
                            <path opacity="0.31" d="M6.26973 34.2413C11.4123 44.4816 23.8438 48.5957 34.0395 43.4531C44.2352 38.3106 48.3492 25.879 43.2067 15.6386C38.0641 5.39824 25.6326 1.2842 15.4369 6.38203C5.24122 11.5246 1.12718 23.9561 6.26973 34.1965V34.2413Z" fill="#D1D1D2"/>
                            <path opacity="0.38" d="M6.9521 33.7062C11.9605 43.6783 24.079 47.7029 34.0511 42.6945C43.9785 37.6861 48.0031 25.5676 42.9947 15.5955C37.9863 5.62343 25.8677 1.59882 15.8957 6.60722C5.9683 11.6156 1.9437 23.7342 6.9521 33.7062Z" fill="#C8C8C9"/>
                            <path opacity="0.44" d="M7.65562 33.2145C12.5299 42.9183 24.3354 46.8534 34.0391 41.9792C43.7429 37.1049 47.6334 25.2994 42.7144 15.5957C37.8402 5.89187 26.0347 1.9567 16.3309 6.83095C6.67182 11.7052 2.78138 23.5107 7.65562 33.2592V33.2145Z" fill="#BEBFC0"/>
                            <path opacity="0.5" d="M8.33206 32.6837C13.0721 42.1191 24.5646 45.9649 34.0001 41.2248C43.4356 36.4847 47.1919 24.9922 42.4518 15.5567C37.7117 6.12127 26.2192 2.27553 16.7837 7.01563C7.34826 11.7557 3.59196 23.2482 8.33206 32.7284V32.6837Z" fill="#B5B6B8"/>
                            <path opacity="0.56" d="M9.00058 32.147C13.6065 41.3142 24.786 45.0258 33.9531 40.4198C43.1203 35.8139 46.7872 24.6344 42.1812 15.4673C37.5753 6.25541 26.3958 2.54382 17.2287 7.14976C8.0615 11.7557 4.39464 22.9352 9.00058 32.147Z" fill="#ACADAF"/>
                            <path opacity="0.62" d="M9.66906 31.6567C14.1408 40.6002 25.0073 44.1777 33.9061 39.7059C42.805 35.2341 46.3824 24.3677 41.9106 15.4241C37.4389 6.48051 26.5724 2.90308 17.6736 7.37487C8.77471 11.8467 5.19727 22.7131 9.66906 31.6567Z" fill="#A3A4A6"/>
                            <path opacity="0.69" d="M10.3376 31.1102C14.6752 39.7855 25.2286 43.2735 33.8591 38.9358C42.4897 34.5982 45.9777 24.0448 41.64 15.3695C37.3024 6.69426 26.749 3.20627 18.1184 7.5439C9.4879 11.8815 5.99991 22.4349 10.3823 31.1102H10.3376Z" fill="#999B9D"/>
                            <path opacity="0.75" d="M11.0137 30.5794C15.2172 38.9864 25.4576 42.3849 33.8198 38.1814C42.2268 33.978 45.5806 23.7376 41.3324 15.3306C37.0842 6.92365 26.8886 3.5251 18.4816 7.72858C10.1194 11.9321 6.72081 22.1724 10.969 30.5794H11.0137Z" fill="#909294"/>
                            <path opacity="0.81" d="M11.6883 30.0894C15.8024 38.2281 25.685 41.5372 33.8237 37.4231C41.9623 33.3538 45.2267 23.4265 41.1127 15.2878C36.9986 7.14915 27.116 3.84003 18.9773 7.90936C10.8834 11.9787 7.61899 21.906 11.6883 30.0447V30.0894Z" fill="#87888B"/>
                            <path opacity="0.88" d="M12.3608 29.5586C16.3407 37.429 25.9103 40.6487 33.7807 36.6688C41.651 32.7336 44.7813 23.1193 40.8461 15.2489C36.8662 7.37857 27.2966 4.15888 19.4263 8.09406C11.5559 12.0292 8.42567 21.6436 12.3608 29.5586Z" fill="#7E7F82"/>
                            <path opacity="0.94" d="M13.0293 29.0159C16.8751 36.6179 26.1317 39.7035 33.7337 35.9025C41.3357 32.1014 44.3765 22.8001 40.5755 15.1981C36.7298 7.55134 27.4732 4.51053 19.8712 8.31154C12.2691 12.1126 9.2283 21.4139 13.0293 29.0606V29.0159Z" fill="#747679"/>
                            <path d="M13.6941 28.5298C17.4057 35.9083 26.3493 38.8596 33.683 35.1481C41.0167 31.4812 43.9681 22.4929 40.2565 15.1592C36.5449 7.78073 27.6014 4.82935 20.2677 8.49621C12.9339 12.2078 9.98254 21.1514 13.6941 28.5298Z" fill="#6B6D70"/>
                        </g>
                        <path d="M6.04434 11.0026C0.767632 20.7063 4.03203 32.333 13.3781 36.9836C22.6794 41.6343 34.5296 37.565 39.8063 27.9059C45.083 18.2021 41.8186 6.57549 32.4726 1.92483C23.1266 -2.72583 11.3211 1.3435 5.99963 11.0026H6.04434Z" fill="url(#paint0_radial_1503_11693)"/>
                        <g style={{mixBlendMode:'screen'}} opacity="0.54">
                            <path style={{mixBlendMode:'screen'}} d="M30.9972 2.10308C22.6797 -0.937739 12.5735 1.61118 6.49187 11.4491C4.61372 14.4452 4.12181 14.8924 6.17883 11.1361C11.4555 1.43231 23.1716 -2.68174 32.4729 2.01364C35.2454 3.39989 33.2331 2.908 30.9972 2.10308Z" fill="url(#paint1_radial_1503_11693)"/>
                        </g>
                        <g style={{mixBlendMode:'soft-light'}} opacity="0.61">
                            <path d="M4.52417 21.1062C5.41853 26.2935 8.5935 30.8994 13.5572 33.3589C22.3666 37.7412 33.5461 33.9402 38.5097 24.7731C41.4611 19.3622 41.5953 13.2806 39.3594 8.31689C42.7132 13.683 43.071 20.9273 39.6277 27.2325C34.4851 36.5786 23.0374 40.5138 14.0491 35.9972C8.19104 33.0906 4.79249 27.322 4.5689 21.0615L4.52417 21.1062Z" fill="url(#paint2_radial_1503_11693)"/>
                        </g>
                        <g style={{mixBlendMode:'overlay'}}>
                            <path opacity="0.11" d="M9.56507 6.80607C2.6338 14.4975 2.94681 25.7217 10.2358 31.8481C17.5696 38.0191 29.1515 36.7223 36.0828 29.0308C43.014 21.3394 42.7457 10.1152 35.412 3.94413C28.0783 -2.18222 16.4963 -0.930116 9.56507 6.76136V6.80607Z" fill="url(#paint3_linear_1503_11693)"/>
                            <path opacity="0.22" d="M9.70605 6.76558C2.86422 14.3229 3.17725 25.4129 10.4663 31.4946C17.7106 37.5762 29.1583 36.3688 36.0002 28.7668C42.842 21.1647 42.529 10.1194 35.2399 4.0378C27.9957 -2.04383 16.5479 -0.83645 9.70605 6.76558Z" fill="url(#paint4_linear_1503_11693)"/>
                            <path opacity="0.33" d="M9.79457 6.67281C3.04218 14.1407 3.39992 25.0518 10.5995 31.0888C17.7544 37.1257 29.068 35.9183 35.7756 28.4951C42.528 21.0272 42.1703 10.1161 34.9707 4.07917C27.8159 -1.91302 16.5023 -0.750357 9.79457 6.71753V6.67281Z" fill="url(#paint5_linear_1503_11693)"/>
                            <path opacity="0.44" d="M9.92211 6.63603C3.30387 13.9698 3.66162 24.7468 10.7718 30.6942C17.8819 36.6417 29.0166 35.5238 35.6349 28.19C42.2531 20.8563 41.8954 10.0793 34.7852 4.08712C27.6751 -1.86036 16.5404 -0.742414 9.92211 6.63603Z" fill="url(#paint6_linear_1503_11693)"/>
                            <path opacity="0.56" d="M10.0712 6.53057C3.54235 13.7749 3.94481 24.4177 10.9655 30.2758C17.9862 36.1785 28.9868 35.0606 35.5156 27.861C42.0444 20.6167 41.642 9.97384 34.6213 4.11581C27.6006 -1.78695 16.6 -0.669006 10.0712 6.53057Z" fill="url(#paint7_linear_1503_11693)"/>
                            <path opacity="0.67" d="M10.1554 6.501C3.71601 13.6111 4.16318 24.1198 11.0944 29.9332C18.0257 35.7465 28.8922 34.718 35.2868 27.6078C41.7262 20.4977 41.279 9.98899 34.3477 4.17567C27.4165 -1.63765 16.5947 -0.609142 10.1554 6.501Z" fill="url(#paint8_linear_1503_11693)"/>
                            <path opacity="0.78" d="M10.2864 6.44632C3.98115 13.467 4.42833 23.7969 11.2702 29.5207C18.1567 35.2893 28.8443 34.3055 35.1495 27.2848C41.4547 20.2641 41.0523 9.93432 34.1657 4.16571C27.3239 -1.60289 16.6363 -0.574383 10.2864 6.4016V6.44632Z" fill="url(#paint9_linear_1503_11693)"/>
                            <path opacity="0.89" d="M10.4241 6.35283C4.20832 13.2394 4.70022 23.4351 11.4526 29.1142C18.2497 34.8381 28.8031 33.8543 35.0189 26.9678C41.2347 20.0812 40.7875 9.88554 33.9457 4.20637C27.1486 -1.47279 16.6399 -0.533719 10.3794 6.35283H10.4241Z" fill="url(#paint10_linear_1503_11693)"/>
                            <path d="M10.5101 6.30824C4.38371 13.0606 4.8756 23.1222 11.628 28.7566C18.3357 34.3911 28.7549 33.4967 34.8366 26.6996C40.9629 19.9472 40.471 9.88567 33.7186 4.25122C27.0109 -1.38323 16.6364 -0.444152 10.5101 6.30824Z" fill="url(#paint11_linear_1503_11693)"/>
                        </g>
                        <path opacity="0.05" d="M37.6815 12.7516C38.7548 18.3413 37.3685 21.4268 36.5636 23.305C35.8481 25.049 38.4865 23.3497 38.5312 23.305C41.7956 18.1624 39.5597 12.1255 38.7995 11.6336C38.0393 11.1417 37.5474 12.1255 37.6815 12.7516Z" fill="white"/>
                        <path opacity="0.1" d="M37.7585 12.9352C38.7423 18.2119 37.4902 21.208 36.6853 23.0862C35.9698 24.7854 38.474 23.2203 38.5187 23.0862C41.6042 18.1672 39.5472 12.3986 38.787 11.862C38.0268 11.3254 37.6243 12.3539 37.7138 12.9352H37.7585Z" fill="white"/>
                        <path opacity="0.15" d="M37.8933 13.1052C38.8324 18.1136 37.625 21.0203 36.8648 22.8537C36.1941 24.553 38.5194 23.0326 38.6088 22.8537C41.5155 18.1136 39.5926 12.7028 38.8771 12.1214C38.2064 11.5401 37.8039 12.6133 37.8933 13.1499V13.1052Z" fill="white"/>
                        <path opacity="0.2" d="M37.984 13.2859C38.8336 17.9813 37.7604 20.7985 37.0002 22.6319C36.3294 24.2865 38.5206 22.8555 38.61 22.5872C41.3378 18.026 39.5491 12.9282 38.9231 12.3021C38.297 11.7208 37.8945 12.7493 37.984 13.2859Z" fill="white"/>
                        <path opacity="0.25" d="M38.0736 13.4611C38.8785 17.8882 37.85 20.616 37.1345 22.4047C36.4637 24.0592 38.5207 22.673 38.6102 22.36C41.1591 17.9776 39.5045 13.1928 38.9232 12.5668C38.3419 11.9407 37.9841 13.0139 38.0736 13.4611Z" fill="white"/>
                        <path opacity="0.3" d="M38.2082 13.6418C38.9684 17.7559 38.0293 20.3942 37.3139 22.1829C36.6878 23.7928 38.566 22.496 38.7001 22.0935C41.0702 17.9347 39.5498 13.463 39.0131 12.7922C38.4765 12.1214 38.1188 13.2394 38.2082 13.6418Z" fill="white"/>
                        <path opacity="0.35" d="M38.2981 13.8267C38.9689 17.6724 38.1639 20.2213 37.4484 21.9653C36.8224 23.5752 38.5664 22.3678 38.7005 21.8759C40.9364 17.896 39.5055 13.7372 39.0136 13.0217C38.5217 12.3063 38.2086 13.4689 38.2534 13.8267H38.2981Z" fill="white"/>
                        <path opacity="0.4" d="M38.3818 14.005C39.0079 17.5824 38.2477 19.9972 37.5769 21.7412C36.9509 23.3063 38.5607 22.1884 38.7396 21.6517C40.7966 17.8507 39.4998 14.005 39.0526 13.2895C38.6054 12.574 38.3371 13.692 38.3818 14.0497V14.005Z" fill="white"/>
                        <path opacity="0.44" d="M38.5258 14.1764C39.0624 17.4408 38.4364 19.8109 37.7656 21.5101C37.1843 23.0753 38.6152 22.002 38.7941 21.376C40.6723 17.7538 39.5096 14.2658 39.1071 13.4609C38.7047 12.7007 38.4364 13.8634 38.4811 14.1764H38.5258Z" fill="white"/>
                        <path opacity="0.49" d="M38.6093 14.3523C39.1012 17.3484 38.5198 19.5843 37.8938 21.2836C37.3125 22.804 38.6093 21.8202 38.8329 21.1494C40.5321 17.7509 39.5036 14.5312 39.1459 13.7263C38.7881 12.9214 38.5646 14.1287 38.6093 14.397V14.3523Z" fill="white"/>
                        <path opacity="0.54" d="M38.7493 14.5386C39.1965 17.2217 38.7493 19.4129 38.0785 21.0675C37.4972 22.5879 38.6598 21.6488 38.8834 20.9333C40.4038 17.7136 39.5095 14.807 39.2412 13.9573C38.9281 13.1077 38.7046 14.3598 38.7493 14.5386Z" fill="white"/>
                        <path opacity="0.59" d="M38.8424 14.7081C39.2001 17.1228 38.8424 19.1798 38.2164 20.8344C37.6797 22.3101 38.6635 21.5052 38.8871 20.6555C40.2287 17.6147 39.5132 15.0658 39.2449 14.1714C38.9766 13.3218 38.7977 14.5292 38.8424 14.7081Z" fill="white"/>
                        <path opacity="0.64" d="M38.9216 14.888C39.2346 17.0345 38.9216 19.0021 38.3403 20.5672C37.8037 22.0429 38.6533 21.2827 38.9216 20.3883C40.129 17.5264 39.5029 15.2905 39.2794 14.3514C39.0558 13.4571 38.9216 14.7092 38.9216 14.8433V14.888Z" fill="white"/>
                        <path opacity="0.69" d="M39.0629 15.0802C39.2865 16.9137 39.0629 18.7918 38.5263 20.3569C37.9897 21.7879 38.7051 21.1172 38.9734 20.1781C40.002 17.495 39.5101 15.5721 39.3312 14.6331C39.1523 13.694 39.0182 14.9908 39.0182 15.0802H39.0629Z" fill="white"/>
                        <path opacity="0.74" d="M39.142 15.247C39.3209 16.8121 39.2315 18.6008 38.6501 20.1212C38.1582 21.5522 38.6948 20.9261 39.0079 19.8976C39.8575 17.4381 39.4998 15.8283 39.3656 14.8445C39.2315 13.8607 39.142 15.2023 39.142 15.247Z" fill="white"/>
                        <path opacity="0.79" d="M39.2427 15.4336C39.2427 16.7304 39.3321 18.385 38.7955 19.9054C38.3036 21.2916 38.7061 20.755 39.0191 19.6818C39.6899 17.4012 39.4663 16.1044 39.3768 15.0758C39.2874 14.092 39.198 15.4336 39.198 15.4336H39.2427Z" fill="white"/>
                        <path style={{mixBlendMode:'screen'}} d="M10.6585 7.33614C4.80042 14.6252 5.60535 24.955 12.4025 30.4106C19.1996 35.8661 29.4847 34.3905 35.298 27.1014C41.156 19.8124 40.3511 9.4826 33.554 4.02702C26.7569 -1.42856 16.5165 0.0471334 10.6585 7.33614Z" fill="url(#paint12_linear_1503_11693)"/>
                        <g style={{mixBlendMode:'overlay'}} opacity="0.56">
                            <path style={{mixBlendMode:'screen'}} d="M13.3266 29.6415C9.43619 26.7796 7.06613 22.442 6.26121 17.7466C8.09464 17.9255 9.97279 18.2832 11.8509 18.7304C20.0343 20.8321 26.7867 25.3933 30.7666 30.8042C25.311 33.7556 18.6481 33.5767 13.3266 29.6415Z" fill="url(#paint13_linear_1503_11693)"/>
                        </g>
                        <defs>
                            <radialGradient id="paint0_radial_1503_11693" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(4.53838 32.3047) rotate(-153.41) scale(49.9033 52.6155)">
                            <stop offset="0.32" stopColor="#008013"/>
                            <stop offset="0.33" stopColor="#008012"/>
                            <stop offset="0.75" stopColor="#008000"/>
                            <stop offset="1" stopColor="#008000"/>
                            </radialGradient>
                            <radialGradient id="paint1_radial_1503_11693" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(19.4781 -1.0851) rotate(170.31) scale(13.7284 13.7284)">
                            <stop offset="0.1" stopColor="#A9A9A9"/>
                            <stop offset="0.48" stopColor="#A9A9A9"/>
                            <stop offset="0.52" stopColor="#99A599"/>
                            <stop offset="0.69" stopColor="#589558"/>
                            <stop offset="0.83" stopColor="#288928"/>
                            <stop offset="0.94" stopColor="#0A820A"/>
                            <stop offset="1" stopColor="#008000"/>
                            </radialGradient>
                            <radialGradient id="paint2_radial_1503_11693" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(7.8158 35.291) rotate(170.31) scale(34.1197 34.1197)">
                            <stop offset="0.08" stopColor="#2C2C2C"/>
                            <stop offset="0.12" stopColor="#434343"/>
                            <stop offset="0.23" stopColor="#787878"/>
                            <stop offset="0.34" stopColor="#A2A2A2"/>
                            <stop offset="0.43" stopColor="#C0C0C0"/>
                            <stop offset="0.51" stopColor="#D2D2D2"/>
                            <stop offset="0.57" stopColor="#D9D9D9"/>
                            <stop offset="0.66" stopColor="#D6D6D6"/>
                            <stop offset="0.73" stopColor="#CDCDCD"/>
                            <stop offset="0.79" stopColor="#BDBDBD"/>
                            <stop offset="0.84" stopColor="#A8A8A8"/>
                            <stop offset="0.89" stopColor="#8C8C8C"/>
                            <stop offset="0.93" stopColor="#696969"/>
                            <stop offset="0.97" stopColor="#414141"/>
                            <stop offset="0.99" stopColor="#2C2C2C"/>
                            </radialGradient>
                            <linearGradient id="paint3_linear_1503_11693" x1="14.3882" y1="38.253" x2="29.6189" y2="-1.24956" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#008013"/>
                            <stop offset="0.44" stopColor="#008004"/>
                            <stop offset="0.58" stopColor="#028102"/>
                            <stop offset="0.73" stopColor="#038904"/>
                            <stop offset="1" stopColor="#059407"/>
                            </linearGradient>
                            <linearGradient id="paint4_linear_1503_11693" x1="13.7307" y1="39.8512" x2="28.9006" y2="0.544868" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#008013"/>
                            <stop offset="0.44" stopColor="#008003"/>
                            <stop offset="0.58" stopColor="#038204"/>
                            <stop offset="0.73" stopColor="#068A08"/>
                            <stop offset="1" stopColor="#0B950E"/>
                            </linearGradient>
                            <linearGradient id="paint5_linear_1503_11693" x1="12.7329" y1="41.3999" x2="27.7093" y2="2.77049" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#008013"/>
                            <stop offset="0.44" stopColor="#008003"/>
                            <stop offset="0.58" stopColor="#058307"/>
                            <stop offset="0.73" stopColor="#098B0D"/>
                            <stop offset="1" stopColor="#109616"/>
                            </linearGradient>
                            <linearGradient id="paint6_linear_1503_11693" x1="12.1325" y1="42.8385" x2="27.0593" y2="4.48991" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#008013"/>
                            <stop offset="0.44" stopColor="#008002"/>
                            <stop offset="0.58" stopColor="#078409"/>
                            <stop offset="0.74" stopColor="#0D8C12"/>
                            <stop offset="1" stopColor="#15971D"/>
                            </linearGradient>
                            <linearGradient id="paint7_linear_1503_11693" x1="11.6919" y1="44.2781" x2="26.5243" y2="6.17482" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#008013"/>
                            <stop offset="0.44" stopColor="#008002"/>
                            <stop offset="0.58" stopColor="#08850B"/>
                            <stop offset="0.78" stopColor="#128F19"/>
                            <stop offset="1" stopColor="#1B9824"/>
                            </linearGradient>
                            <linearGradient id="paint8_linear_1503_11693" x1="10.9357" y1="45.7196" x2="25.5731" y2="8.28064" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#008013"/>
                            <stop offset="0.44" stopColor="#008001"/>
                            <stop offset="0.45" stopColor="#008002"/>
                            <stop offset="0.58" stopColor="#0A860D"/>
                            <stop offset="0.81" stopColor="#179220"/>
                            <stop offset="1" stopColor="#20992B"/>
                            </linearGradient>
                            <linearGradient id="paint9_linear_1503_11693" x1="10.4916" y1="46.9536" x2="25.0759" y2="9.79109" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#008013"/>
                            <stop offset="0.44" stopColor="#008001"/>
                            <stop offset="0.47" stopColor="#038105"/>
                            <stop offset="0.58" stopColor="#0C8710"/>
                            <stop offset="0.83" stopColor="#1D9428"/>
                            <stop offset="1" stopColor="#259A33"/>
                            </linearGradient>
                            <linearGradient id="paint10_linear_1503_11693" x1="10.1575" y1="48.1963" x2="24.6882" y2="11.2688" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#008012"/>
                            <stop offset="0.44" stopColor="#008000"/>
                            <stop offset="0.49" stopColor="#058307"/>
                            <stop offset="0.58" stopColor="#0D8812"/>
                            <stop offset="0.85" stopColor="#22952F"/>
                            <stop offset="1" stopColor="#2B9B3A"/>
                            </linearGradient>
                            <linearGradient id="paint11_linear_1503_11693" x1="9.94934" y1="49.3218" x2="24.344" y2="12.6835" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#008012"/>
                            <stop offset="0.44" stopColor="#008000"/>
                            <stop offset="0.62" stopColor="#128A18"/>
                            <stop offset="0.86" stopColor="#279736"/>
                            <stop offset="1" stopColor="#309C41"/>
                            </linearGradient>
                            <linearGradient id="paint12_linear_1503_11693" x1="28.2385" y1="-3.61022" x2="12.7476" y2="38.0826" gradientUnits="userSpaceOnUse">
                            <stop offset="0.1" stopColor="white"/>
                            <stop offset="0.15" stopColor="#BCBCBC"/>
                            <stop offset="0.2" stopColor="#7A7A7A"/>
                            <stop offset="0.24" stopColor="#454545"/>
                            <stop offset="0.28" stopColor="#1F1F1F"/>
                            <stop offset="0.31" stopColor="#080808"/>
                            <stop offset="0.33"/>
                            <stop offset="0.48" stopColor="#020202"/>
                            <stop offset="0.57" stopColor="#0A0A0A"/>
                            <stop offset="0.64" stopColor="#181818"/>
                            <stop offset="0.71" stopColor="#2D2D2D"/>
                            <stop offset="0.76" stopColor="#474747"/>
                            <stop offset="0.82" stopColor="#676767"/>
                            <stop offset="0.87" stopColor="#8D8D8D"/>
                            <stop offset="0.92" stopColor="#B9B9B9"/>
                            <stop offset="0.96" stopColor="#EAEAEA"/>
                            <stop offset="0.98" stopColor="white"/>
                            </linearGradient>
                            <linearGradient id="paint13_linear_1503_11693" x1="5.21204" y1="43.8808" x2="18.7481" y2="20.281" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#A9A9A9"/>
                            <stop offset="0.06" stopColor="#8E8E8E"/>
                            <stop offset="0.19" stopColor="#5C5C5C"/>
                            <stop offset="0.31" stopColor="#343434"/>
                            <stop offset="0.42" stopColor="#171717"/>
                            <stop offset="0.51" stopColor="#060606"/>
                            <stop offset="0.58"/>
                            <stop offset="0.69" stopColor="#020202"/>
                            <stop offset="0.76" stopColor="#0A0A0A"/>
                            <stop offset="0.81" stopColor="#181818"/>
                            <stop offset="0.86" stopColor="#2D2D2D"/>
                            <stop offset="0.9" stopColor="#474747"/>
                            <stop offset="0.94" stopColor="#676767"/>
                            <stop offset="0.98" stopColor="#8C8C8C"/>
                            <stop offset="1" stopColor="#A9A9A9"/>
                            </linearGradient>
                        </defs>
                    </svg> : false}
                </div>

             </div>  : false}
        </div>

        
    );
};

export default Card;