import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';



export const fetchCountry = createAsyncThunk(
    'chatBot/fetchCountry',
    async () => {
        // const response = await axios.api.getCountry();
        // return await response;
        const responce = await fetch('https://pay.voicex.biz:7260/api/Countrys');
        const data = await responce.json();
        return data;
    })

const chatBotSlice = createSlice({
    name: 'chatBot',
    initialState: 
    {
        status: null,
        error: null,
        localStorageIdCountry: [],
        country: [],
        openMap: null,
    },
    
    reducers: {

        isOpenMap(state, action)
        {
            state.openMap = action.payload < 3 ? true : false;
        },
        postIdCounry(state, action)
        {
            if(state.localStorageIdCountry.length === 0)
            {
            }
            state.localStorageIdCountry = action.payload;
        }
    },
    extraReducers: (builder) =>
    {
        builder 
            .addCase(fetchCountry.pending, (state) =>
            {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(fetchCountry.fulfilled, (state, action) =>
            {
                state.status = 'resolved';
                // state.country = action.payload;
                // state.country = state.country.Data.map((country, index) => ({
                //     ...state.country,
                //     id: index + 1
                // }))
                // state.country = action.payload.Data.forEach((country, index) =>
                // {
                //     country.id = index + 1;
                // })
                state.country = {
                    ...action.payload,
                    Data: action.payload.Data.map((country, index) => ({
                        ...country,
                        id: index + 1 
                        }))
                        };
            })
            .addCase(fetchCountry.rejected, (state, action) =>
            {

            })
    }
})
export const {isOpenMap, postIdCounry} = chatBotSlice.actions;
export default chatBotSlice.reducer;